<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <AppraisalEmpReportDisTable :permissions="permissionsList" />
      </md-card>
    </div>
  </div>
</template>

<script>
import { Tabs } from '@/components';
import $ from 'jquery';
import moment from 'moment';
import Vuex from 'vuex';
import AppraisalEmpReportDisTable from './AppraisalEmpReportDisTable.vue';
import employeeMixin from '@/mixins/employee-mixin';
import { loading_start, loading_close } from '@/utils/loading.js';
import _ from 'lodash';

export default {
  mixins: [employeeMixin],
  components: {
    AppraisalEmpReportDisTable,
  },

  data() {
    return {
      // tabactive: 0,
      // tabList: {
      //   name: [],
      //   icon: [],
      // },
      permissionsList: [],
      // permissions: [],
      // employee_id: null,
      // months: moment()
      //   // .subtract(1, 'month')
      //   .month(),
      // years: moment().year(),
      // branchs: {},
      // isParams: false,
      // me: null,
      // query: null,
      // available_branches: null,
      // available_ots: [],
      // com_id: null,
    };
  },

  async created() {
    if (this.me?.employee_id) {
      this.profile = await this.getProfileData(this.me.employee_id);
    } else {
      this.profile = await this.getProfileData();
    }
    // this.getProfile().then(() => {
    // this.setPermission(this.me);
    // this.setEmployeeID(this.me).then(() => {
    //   this.isParams = true;
    //   if (this.permissionsList.includes('process salary')) {
    //     this.tabList.name = [
    //       'ตั้งค่าอนุมัติ ลา OT',
    //       'ตั้งค่าอนุมัติรายการ OT เหมา',
    //       'ตั้งค่าอนุมัติรายงาน เที่ยวเรือและค่านวดสปา',
    //     ];
    //     this.tabList.icon = [
    //       'library_books',
    //       'directions_boat',
    //       'description',
    //     ];
    //   } else {
    //     this.setstaffTab();
    //   }
    // });
    // });
    // if (this.$route.params.tabactive) {
    //   this.tabactive = this.$route.params.tabactive;
    // }
    // loading_start();
    // let params = { profile: true };
    // let employee = await this.$store.dispatch('staffs/searchStaff', params);
    // // await this.getOTType(employee[0].com_id);
    // this.com_id = employee[0].com_id;
    // this.getBranches();
    // loading_close();
  },

  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },

  methods: {
    // async getOTType(id) {
    //   let params = {
    //     filter: {
    //       ...{ company_id: id },
    //     },
    //   };
    //   await this.$store.dispatch('ots/list', params);
    //   const ottypelist = this.$store.getters['ots/list'];
    //   var arr = [];
    //   var array = [];
    //   if (ottypelist) {
    //     $.each(ottypelist, function(i, v) {
    //       let item = { id: v['id'], name: v['name'] };
    //       if ([4, 5, 6].includes(item.id)) {
    //         arr.push(item);
    //       } else if ([1, 2, 3].includes(item.id)) {
    //         array.push(item);
    //       }
    //     });
    //     this.available_ots = arr;
    //   }
    // },
    //   async getBranches() {
    //     let params = {
    //       com_id: this.com_id,
    //       bch_only: '1',
    //     };
    //     await this.$store.dispatch('staffs/searchStaff', params).then((data) => {
    //       this.available_branches = data;
    //     });
    //     if (!this.permissions.includes('process salary')) {
    //       var arr = [];
    //       this.available_branches.map((bch) => {
    //         if (this.permissions.includes(bch.brname_en)) {
    //           arr.push(bch);
    //         }
    //       });
    //       this.available_branches = arr;
    //     }
    //   },
    //   setTabActive() {
    //     if (this.$route.params.tabactive) {
    //       this.tabactive = this.$route.params.tabactive;
    //     }
    //   },
    //   setPermission(val) {
    //     val.permissions.map((permis) => {
    //       this.permissionsList.push(permis.name);
    //       this.permissions.push(permis.name);
    //     });
    //     val.roles[0].permissions.map((permission) => {
    //       this.permissionsList.push(permission.name);
    //       this.permissions.push(permission.name);
    //     });
    //   },
    //   async setEmployeeID(me) {
    //     this.branchs = me.employees.branchs;
    //     this.employee_id = me.employee_id;
    //     // }
    //   },
    //   setstaffTab() {
    //     this.tabList.name = [
    //       'ตั้งค่าอนุมัติ ลา OT',
    //       'ตั้งค่าอนุมัติรายการ OT เหมา',
    //       'ตั้งค่าอนุมัติรายงาน เที่ยวเรือและค่านวดสปา',
    //     ];
    //     this.tabList.icon = ['library_books', 'directions_boat', 'description'];
    //   },
    // async getProfile() {
    //   await this.$store.dispatch('profile/me');
    //   this.me = await this.$store.getters['profile/me'];
    // },
    ...Vuex.mapActions({
      settotal: 'approvers/settotal',
      // setLeaveAmout: 'staffleaves/setLeaveAmout',
      // setOTAmout: 'ots/setOTAmout',
      // setKeepdayAmout: 'keepdays/setKeepdayAmout',
      // setWorkingAmount: 'staffhasworks/setWorkingAmount',
    }),
  },
};
</script>
<style lang="scss">
.tab-pane-1,
.tab-pane-2,
.tab-pane-3,
.tab-pane-4,
.tab-pane-5,
.tab-pane-6 {
  width: 100%;
}
</style>
