<template>
  <md-card>
    <div class="header" @click="headdropdown()">
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>flight</md-icon>
        </div>
        <h4 class="title">ลาชดเชยวันหยุดประเพณี (Holiday Leave)</h4>
      </md-card-header>
    </div>
    <md-card-content class="contentleave">
      <md-table v-model="leave_holiday" v-if="Check_holidays">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="No.">
            {{ leave_holiday.indexOf(item) + 1 }}
          </md-table-cell>
          <md-table-cell md-label="วันหยุดประเพณี (HOLIDAY)">
            {{ item.name_th }}
          </md-table-cell>
          <md-table-cell md-label="วันที่">
            {{ item.holiday_date_format }}
          </md-table-cell>
          <md-table-cell md-label="เปลี่ยนวันหยุด">
            <div v-if="item.active == 1">
              <md-table-row
                v-for="leave in item.leave"
                :key="
                  leave.from_date +
                    leave.to_date +
                    leave.to_date_type +
                    leave.approve_status +
                    leave.approver_firstname +
                    'hd'
                "
                class="hr"
              >
                {{ leave.to_date }}
                <span v-if="leave.to_date_type == 'hm'">
                  ลาช่วงเช้า
                </span>
                <span v-else-if="leave.to_date_type == 'hn'">
                  ลาช่วงบ่าย
                </span>
                <span v-else>เต็มวัน</span>
              </md-table-row>
              <md-table-row
                v-if="item.active_half > 0 && !old_leave"
                class="hr empty"
              >
                <b-datepicker
                  :min="item.holiday_date"
                  placeholder="ลาเปลี่ยนวันหยุด"
                  locale="th"
                  v-model="item.to_date"
                  :date-disabled-fn="disabledDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  required
                ></b-datepicker>
                <md-checkbox
                  v-model="item.is_leaveend_half"
                  v-if="item.active_half >= 1"
                >
                  <span>ลาครึ่งวัน</span>
                </md-checkbox>
                <div v-else>
                  <span style="display:none">
                    {{ (item.is_leaveend_half = true) }}
                  </span>
                </div>
                <md-field v-show="item.is_leaveend_half">
                  <label>เลือกช่วงการลา</label>
                  <md-select v-model="item.to_date_type">
                    <md-option value="hm">ช่วงเช้า</md-option>
                    <md-option value="hn">ช่วงบ่าย</md-option>
                  </md-select>
                </md-field>
              </md-table-row>
            </div>
          </md-table-cell>
          <md-table-cell md-label="สถานะการอนุมัติ">
            <md-table-row
              v-for="leave in item.leave"
              :key="
                leave.from_date +
                  leave.to_date +
                  leave.to_date_type +
                  leave.approve_status +
                  leave.approver_firstname +
                  'hd_status'
              "
              class="hr"
            >
              <div v-if="leave.approve_status == 'leaverequest'">
                <span style="color: blue">รอการอนุมัติ</span>
              </div>
              <!-- <div v-else-if="leave.approve_status == 'pending'">
                  <span style="color: blue">รับทราบและรอการอนุมัติ</span>
                </div> -->
              <div v-else-if="leave.approve_status == 'approve'">
                <span style="color: green">อนุมัติแล้ว</span>
              </div>
              <div v-else-if="leave.approve_status == 'disapprove'">
                <span style="color: red">ไม่อนุมัติ</span>
              </div>
              <div v-else-if="leave.approve_status == 'reject'"></div>
            </md-table-row>
            <md-table-row
              class="hr empty"
              v-if="item.active == 1 && item.active_half > 0"
            ></md-table-row>
          </md-table-cell>
          <md-table-cell md-label="ผู้อนุมัติ">
            <md-table-row
              v-for="leave in item.leave"
              :key="
                leave.from_date +
                  leave.to_date +
                  leave.to_date_type +
                  leave.approver_firstname +
                  'hd_approver'
              "
              class="hr"
            >
              {{ leave.approver_firstname }}
              {{ leave.approver_lastname }}
            </md-table-row>
            <md-table-row
              class="hr empty"
              v-if="item.active == 1 && item.active_half > 0"
            ></md-table-row>
          </md-table-cell>
          <md-table-cell md-label="Action">
            <md-table-row
              v-for="leave in item.leave"
              :key="
                leave.from_date +
                  leave.to_date +
                  leave.to_date_type +
                  leave.approver_firstname +
                  'hd_action'
              "
              class="hr"
            >
              <b-button
                v-if="permissions.includes('process salary') && persons != 0"
                @click="editLeave(leave, 'oneday')"
                class="space-rt"
                size="md"
                variant="warning"
              >
                แก้ไข
              </b-button>
              <b-button
                v-if="
                  leave.approve_status == 'leaverequest' ||
                    (permissions.includes('process salary') && persons != 0)
                "
                @click="deleteLeave(leave)"
                class="space-rt"
                size="md"
                variant="danger"
              >
                ลบ
              </b-button>
            </md-table-row>
            <md-table-row
              class="hr empty"
              v-if="item.active == 1 && item.active_half > 0"
            >
              <b-button
                @click="createleave(item)"
                variant="outline-success"
                v-if="!old_leave"
              >
                เปลี่ยนวันหยุด
              </b-button>
            </md-table-row>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>

<script>
// import { Tabs } from '@/components';
// import Vuex from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  mixins: [employeeMixin],
  data() {
    return {
      Check_holidays: false,
      disabled_min_date: null,
      employee: 0,
      leave_holiday: [],
    };
  },
  props: {
    old_leave: {
      type: Boolean,
      default: false,
    },
    waitdata: {
      type: Boolean,
      default: false,
    },
    emp_id: {
      type: Number,
      default: 0,
    },
    persons: {
      type: Number,
      default: 0,
    },
    leaveholidays: {
      type: Array,
      default: () => ({}),
    },
    permissions: {
      type: Array,
      default: () => ({}),
    },
    years: {
      type: Number,
      default: moment().year(),
    },
  },
  async created() {
    this.employee = await this.getProfileData(this.emp_id);
    await this.Get_Holiday(this.employee[0].com_id).then(() => {
      this.Check_holidays = false;
      this.HolidaysJoinLeave();
      this.Check_holidays = true;
    });
  },

  watch: {
    years: async function() {
      this.Get_Holiday(this.employee[0].com_id).then(() => {
        this.Check_holidays = false;
        this.HolidaysJoinLeave();
        this.Check_holidays = true;
      });
    },
    leaveholidays: async function() {
      this.Get_Holiday(this.employee[0].com_id).then(() => {
        this.Check_holidays = false;
        this.HolidaysJoinLeave();
        this.Check_holidays = true;
      });
    },
  },

  methods: {
    disabledDate(passdate) {
      let date = moment(passdate);
      let now = moment();
      let datediff = now.diff(date, 'days');

      if (this.permissions.includes('process salary') && this.persons != 0) {
        return false;
      }

      return datediff > -3;
    },

    HolidaysJoinLeave() {
      if (this.leave_holiday.length > 0) {
        // var currentyear = moment().year(); // ปิดไม่ให้ลาปีเก่า
        var currentyear = 2022; // ปิดไม่ให้ลาปีน้อยกว่า 2021
        this.leave_holiday.forEach((holidays, index) => {
          var l = [];
          if (holidays.half == 1) {
            holidays.active_half = 0.5; // มีครึ่งวัน
          } else {
            holidays.active_half = 1; // มีเต็มวัน
          }
          if (this.leaveholidays.length > 0) {
            this.leaveholidays.forEach((leave) => {
              let from_date = moment(leave.from_date, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
              );

              if (from_date == holidays.holiday_date) {
                l.push(leave);
                if (leave.approve_status === 'approve') {
                  if (leave.to_date_type != 'f') {
                    holidays.active_half -= 0.5;
                  } else {
                    holidays.active_half -= 1;
                  }
                } else if (leave.approve_status === 'disapprove') {
                } else {
                  if (leave.to_date_type != 'f') {
                    holidays.active_half -= 0.5;
                  } else {
                    holidays.active_half -= 1;
                  }
                }
              }
              if (this.years < currentyear) {
                holidays.active_half = 0;
              }
            });
          }
          holidays.leave = l;
        });
        // this.Check_holidays = true;
      } else {
        // this.Check_holidays = false;
      }
    },

    async Get_Holiday(company_id) {
      let year = this.years;
      let params = {
        filter: {
          ...{ emp_id: this.emp_id },
          ...{ company_id: company_id },
          ...{ year: year },
        },
      };
      await this.$store.dispatch('holidays/list', params);
      let temp_holiday = this.$store.getters['holidays/list'];

      await Promise.all(
        temp_holiday.map((hd) => {
          if (moment(hd.holiday_date) <= moment().add(1, 'months')) {
            hd.active = 1;
          } else {
            hd.active = 0;
          }
          hd.holiday_date_format = moment(hd.holiday_date).format('DD/MM/YYYY');
          hd.approve_status = '';
        }),
      );
      this.leave_holiday = temp_holiday;
    },

    editLeave(leave, type = '') {
      this.$emit('editLeave', leave, type);
    },

    deleteLeave(leave) {
      this.$emit('deleteLeave', leave);
    },

    createleave(item, id) {
      item.leave_id = 12;
      let temp = { ...item };
      temp.from_date = temp.holiday_date;
      if (!temp.to_date) {
        this.$store.dispatch('alerts/error', 'กรุณาเลือกวันที่ต้องการเปลี่ยน');
        return false;
      }
      if (temp.is_leaveend_half) {
        if (temp.to_date_type) {
          temp.from_date_type = temp.to_date_type;
        } else {
          this.$store.dispatch('alerts/error', 'กรุณาเลือกช่วงการลา');
          return false;
        }
      } else {
        temp.from_date_type = 'f';
        temp.to_date_type = 'f';
      }
      let del = [
        'id',
        'links',
        'is_leaveend_half',
        'holiday_date',
        'holiday_date_format',
        'name_en',
        'name_th',
        'type',
        'year',
        'company_id',
        'active',
        'active_half',
        'leave',
        'created_at',
        'updated_at',
      ];
      item.is_leaveend_half = false;
      item.to_date = null;
      item.to_date_type = null;
      del.forEach((del) => delete temp[del]);
      this.$emit('createleave', temp);
    },
  },
};
</script>
<style></style>
