export default {
  name: 'MemoTemplate',
  methods: {
    getBaseTemplate({ branchLogo, memorandam }) {
      return {
        schemas: [
          [
            this.getBranchLogoTemplate({ branchLogo }),
            this.getMemorandumTemplate(),
            this.getMemorandumTableTemplate({ memorandam }),
          ],
          [this.getBranchLogoTemplate({ branchLogo })],
        ],
        basePdf: {
          width: 210,
          height: 297,
          padding: [15, 15, 15, 15],
        },
        pdfmeVersion: '5.0.0',
      };
    },
    getBranchLogoTemplate({ branchLogo }) {
      return {
        name: 'branch_logo',
        type: 'image',
        content: branchLogo,
        position: { x: 66.48, y: 0.79 },
        width: 77.02,
        height: 15.64,
        rotate: 0,
        opacity: 1,
        required: false,
      };
    },
    getMemorandumTemplate() {
      return {
        name: 'memorandum',
        type: 'text',
        content: 'MEMORANDUM',
        position: { x: 65.96, y: 18.99 },
        width: 78.06,
        height: 6.02,
        rotate: 0,
        alignment: 'center',
        verticalAlignment: 'top',
        fontSize: 16,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: '#000000',
        backgroundColor: '',
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: 'Angsana New Bold',
      };
    },
    getMemorandumTableTemplate({ memorandam }) {
      return {
        name: 'memorandam_table',
        type: 'table',
        position: { x: 20.5, y: 31 },
        width: 169,
        height: 24,
        content: `[["FROM: ${memorandam?.fromText}","PAGE: ${memorandam?.pageText}"],["SUBJECT: ${memorandam?.subjectText}","CC: ${memorandam?.cc}"]]`,
        showHead: true,
        head: [`TO: ${memorandam?.toText}`, `DATE: ${memorandam?.dateMemo}`],
        headWidthPercentages: [50, 50],
        tableStyles: { borderWidth: 0.03, borderColor: '#000000' },
        headStyles: {
          fontName: 'Angsana New Bold',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'left',
          verticalAlignment: 'middle',
          lineHeight: 0,
          fontColor: '#000000',
          borderColor: '#000000',
          backgroundColor: '#ffffff',
          borderWidth: {
            top: 0.03,
            right: 0.03,
            bottom: 0.03,
            left: 0.03,
          },
          padding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
        bodyStyles: {
          fontName: 'Angsana New Bold',
          fontSize: 16,
          characterSpacing: 0,
          alignment: 'left',
          verticalAlignment: 'middle',
          lineHeight: 0,
          fontColor: '#000000',
          borderColor: '#000000',
          backgroundColor: '#ffffff',
          alternateBackgroundColor: '#ffffff',
          borderWidth: {
            top: 0.03,
            right: 0.03,
            bottom: 0.03,
            left: 0.03,
          },
          padding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
        columnStyles: {},
        required: false,
        readOnly: false,
      };
    },
    getTemplateSignature() {
      return [
        {
          name: 'dotted_line',
          type: 'text',
          content: 'DOTTED_LINE',
          position: { x: 1, y: 1 },
          width: 54.96,
          height: 5.75,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 16,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'name',
          type: 'text',
          content: 'NAME',
          position: { x: 1, y: 8 },
          width: 53,
          height: 7,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'middle',
          fontSize: 18,
          lineHeight: 1,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
        {
          name: 'position',
          type: 'text',
          content: 'POSITION',
          position: { x: 1, y: 14 },
          width: 53,
          height: 12.29,
          rotate: 0,
          alignment: 'center',
          verticalAlignment: 'top',
          fontSize: 18,
          lineHeight: 0.8,
          characterSpacing: 0,
          fontColor: '#000000',
          backgroundColor: '',
          opacity: 1,
          strikethrough: false,
          underline: false,
          required: false,
          readOnly: false,
          fontName: 'Angsana New',
        },
      ];
    },
    getInputsSignature(value) {
      return {
        type: value.type,
        dotted_line: '.....................................',
        name: value.name,
        position: value.position,
      };
    },
  },
};
