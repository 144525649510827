<template>
  <div class="memoList">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>list</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="gotoCreate()" class="md-success md-dense">
            สร้าง Memo
          </md-button>
          <md-button @click="goBack()" class="md-primary md-dense">
            Back to SanthiyaSOS
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div
          class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
          style="text-align: left"
        >
          <label style="margin-top: 1.3rem; text-align: right">
            ค้นหา:
          </label>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Search</label>
            <md-input
              class="md-size-50 searchID"
              v-model="keySearchMemo"
              @keyup.enter="setMemoList()"
            ></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Limit</label>
            <md-select class="md-size-50" v-model="limit">
              <md-option
                v-for="(item, index) in limitList"
                :key="index + 'limitList'"
                :value="item"
              >
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          style="text-align: left"
        >
          totalrows: {{ totalrows }} currentPageMemo: {{ currentPageMemo }}
          <b-pagination
            :value="currentPageMemo"
            :total-rows="totalrows"
            :per-page="limit"
            @input="handlePageChange"
          />
          <tabs
            :tab-name="['Pending Approval', 'Approved']"
            :tab-icon="['update', 'library_books']"
            :tab-active="0"
            nav-pills-icons
            plain
            color-button="warning"
          >
            <!-- here you can add your content for tab-content -->
            <template slot="tab-pane-1">
              <md-card>
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>No.</b-th>
                      <b-th sticky-column>Memo Name</b-th>
                      <b-th>Memo Date</b-th>
                      <b-th>Status</b-th>
                      <b-th>Create Date</b-th>
                      <b-th>Update Date</b-th>
                      <b-th>CreateBy / UpdateBy</b-th>
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(memo, index) in memoList"
                      :key="index + 'memoList'"
                    >
                      <b-td
                        >{{ index + 1 + (currentPageMemo - 1) * limit }}
                      </b-td>
                      <b-th sticky-column>{{ memo.memo_name }}</b-th>
                      <b-td>{{ memo.date }}</b-td>
                      <b-td class="nowarp">
                        <span
                          v-if="memo.status_approve == 0"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">รออนุมัติ</span>
                          <span v-else>WAIT APPROVE</span>
                        </span>
                        <span
                          v-if="memo.status_approve == -1"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">แบบร่าง</span>
                          <span v-else>DRAFT</span>
                        </span>
                        <span
                          v-if="memo.status_approve == 2"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">ตรวจสอบใหม่</span>
                          <span v-else>RECHECK</span>
                        </span>
                        <span
                          v-else-if="memo.status_approve == 3"
                          class="approved"
                        >
                          <span v-if="lang == 'th'">อนุมัติ</span>
                          <span v-else>APPROVED</span>
                        </span>
                      </b-td>
                      <b-td>{{ memo.created_at }}</b-td>
                      <b-td>{{ memo.updated_at }}</b-td>
                      <b-td class="nowarp">
                        <span>
                          CreateBy:
                          {{ memo.empcreate_emp_id }}
                          {{
                            `${memo.empcreate_titlename_en} ${memo.empcreate_firstname_en} ${memo.empcreate_lastname_en}`
                          }}
                          {{
                            `${memo.empcreate_titlename_th} ${memo.empcreate_firstname_th} ${memo.empcreate_lastname_th}`
                          }}
                        </span>
                        <span
                          v-if="
                            memo.empupdate_emp_id &&
                              memo.empcreate_emp_id != memo.empupdate_emp_id
                          "
                        >
                          <br />
                          {{ memo.empupdate_emp_id }}
                          {{
                            `${memo.empupdate_titlename_en} ${memo.empupdate_firstname_en} ${memo.empupdate_lastname_en}`
                          }}
                          {{
                            `${memo.empupdate_titlename_th} ${memo.empupdate_firstname_th} ${memo.empupdate_lastname_th}`
                          }}
                        </span>
                      </b-td>
                      <b-td>
                        <b-button
                          variant="info"
                          class="mr-2"
                          @click="viewApprover(memo.id)"
                          v-if="
                            memo.status_approve !== -1 &&
                              memo.status_approve !== 2
                          "
                        >
                          Approvers
                        </b-button>
                        <b-button
                          variant="warning"
                          class="mr-2"
                          @click="gotoEdit(memo.id)"
                          v-if="memo.status_approve === -1"
                        >
                          Edit
                        </b-button>
                        <b-button
                          variant="warning"
                          class="mr-2"
                          @click="gotoEdit(memo.id)"
                          v-if="memo.status_approve === 2"
                        >
                          Recheck
                        </b-button>
                        <b-button
                          variant="primary"
                          class="mr-2"
                          @click="preview(memo?.id)"
                          v-if="memo.status_approve !== -1"
                        >
                          Preview
                        </b-button>
                        <b-button
                          variant="danger"
                          class="mr-2"
                          @click="gotoDelete(memo.id)"
                          v-if="memo.status_approve !== -1"
                        >
                          Delete
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </md-card>
            </template>
            <template slot="tab-pane-2">
              <md-card>
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>No.</b-th>
                      <b-th sticky-column>Memo Name</b-th>
                      <b-th>Memo Date</b-th>
                      <b-th>Status</b-th>
                      <b-th>Create Date</b-th>
                      <b-th>Update Date</b-th>
                      <b-th>CreateBy / UpdateBy</b-th>
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(memo, index) in approvedMemos"
                      :key="index + 'memoList'"
                    >
                      <b-td>
                        {{ index + 1 + (currentPageMemo - 1) * limit }}
                      </b-td>
                      <b-th sticky-column>{{ memo.memo_name }}</b-th>
                      <b-td>{{ memo.date }}</b-td>
                      <b-td class="nowarp">
                        <span
                          v-if="memo.status_approve == 0"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">รออนุมัติ</span>
                          <span v-else>WAIT APPROVE</span>
                        </span>
                        <span
                          v-if="memo.status_approve == -1"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">แบบร่าง</span>
                          <span v-else>DRAFT</span>
                        </span>
                        <span
                          v-if="memo.status_approve == 2"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">ตรวจสอบใหม่</span>
                          <span v-else>RECHECK</span>
                        </span>
                        <span
                          v-else-if="memo.status_approve == 3"
                          class="approved"
                        >
                          <span v-if="lang == 'th'">อนุมัติ</span>
                          <span v-else>APPROVED</span>
                        </span>
                      </b-td>
                      <b-td>{{ memo.created_at }}</b-td>
                      <b-td>{{ memo.updated_at }}</b-td>
                      <b-td class="nowarp">
                        <span>
                          CreateBy:
                          {{ memo.empcreate_emp_id }}
                          {{
                            `${memo.empcreate_titlename_en}  ${memo.empcreate_firstname_en} ${memo.empcreate_lastname_en}`
                          }}
                          {{
                            `${memo.empcreate_titlename_th} ${memo.empcreate_firstname_th} ${memo.empcreate_lastname_th}`
                          }}
                        </span>
                        <span
                          v-if="
                            memo.empupdate_emp_id &&
                              memo.empcreate_emp_id != memo.empupdate_emp_id
                          "
                        >
                          <br />
                          {{ memo.empupdate_emp_id }}
                          {{
                            `${memo.empupdate_titlename_en} ${memo.empupdate_firstname_en} ${memo.empupdate_lastname_en}`
                          }}
                          {{
                            `${memo.empupdate_titlename_th} ${memo.empupdate_firstname_th} ${memo.empupdate_lastname_th}`
                          }}
                        </span>
                      </b-td>
                      <b-td>
                        <b-button variant="primary" class="mr-2">
                          Preview
                        </b-button>
                        <b-button
                          variant="success"
                          class="ml-2"
                          @click="gotoPublish(memo.id)"
                        >
                          Set Publish
                        </b-button>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </md-card>
            </template>
          </tabs>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog class="memoList" :md-active.sync="showApprover">
      <!-- <md-dialog-title>Approver</md-dialog-title> -->
      <md-dialog-content>
        <p style="font-size:20px" v-if="approverList?.approver?.length > 0">
          ผู้อนุมัติ
        </p>
        <md-list v-if="approverList?.approver?.length > 0">
          <md-list-item
            v-for="(app, index) in approverList['approver']"
            :key="index + 'approverList'"
          >
            <p>
              {{ app.emp_first_name + ' ' + app.emp_last_name }}
              <span>
                <span v-if="app.status_approve == 3">อนุมัติ</span>
                <span v-else-if="app.status_approve == 0">รออนุมัติ</span>
                <md-icon class="app approve" v-if="app.status_approve == 3">
                  check
                </md-icon>
                <md-icon class="app wait" v-else-if="app.status_approve == 0">
                  pending
                </md-icon>
              </span>
            </p>
          </md-list-item>
        </md-list>
        <p style="font-size:20px" v-if="approverList?.acknowledge?.length > 0">
          ผู้รับทราบ
        </p>
        <md-list v-if="approverList?.acknowledge?.length > 0">
          <md-list-item
            v-for="(app, index) in approverList['acknowledge']"
            :key="index + 'approverList'"
          >
            <p>
              {{ app.emp_first_name + ' ' + app.emp_last_name }}
              <span>
                <span v-if="app.status_approve == 3">อนุมัติ</span>
                <span v-else-if="app.status_approve == 0">รออนุมัติ</span>
                <md-icon class="app approve" v-if="app.status_approve == 3">
                  check
                </md-icon>
                <md-icon class="app wait" v-else-if="app.status_approve == 0">
                  pending
                </md-icon></span
              >
            </p>
          </md-list-item>
        </md-list>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showApprover = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <PdfmeEditor
      ref="pdfmeEditor"
      :contentTemplate="pdfme_template"
      :hidden="true"
    >
    </PdfmeEditor>
  </div>
</template>
<script>
import { Tabs } from '@/components';
import { loading_close, loading_start } from '@/utils/loading.js';
import moment from 'moment';
import Vuex from 'vuex';
import PdfmeEditor from '@/components/Pdfme/RichEditor_Pdfme.vue';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';

export default {
  mixins: [MemoMixin],
  components: {
    PdfmeEditor,
    Tabs,
  },
  async created() {
    this.keySearchMemo = this.$route.params.keySearchMemo || '';
    this.currentPageMemo = this.$route.params.currentPageMemo || 1;
    await this.setMemoList();
  },

  data() {
    return {
      memorandam: {},
      pdfme_template: undefined,
      currentPageMemo: 1,
      limitList: [15, 30, 50, 100],
      limit: 15,
      totalrows: 0,
      keySearchMemo: '',
      memoList: [],
      mamoData: [],
      approvedMemos: [],
      approverList: [],
      showApprover: false,
    };
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      lang: (state) => state.profile.lang,
    }),
  },
  watch: {
    async currentPageMemo() {
      await this.setMemoList();
    },
    async limit() {
      await this.setMemoList();
    },
  },
  methods: {
    goBack() {
      let keySearch = this.$route.params.keySearch;
      let currentPage = this.$route.params.currentPage;

      this.$router.push({
        path: 'santhiayaSOS-management',
        name: 'จัดการสัญธิญา SOS',
        params: {
          keySearch,
          currentPage,
        },
      });
    },
    gotoCreate() {
      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: {
          keySearchMemo: this.keySearchMemo,
          currentPageMemo: this.currentPageMemo,
        },
      });
    },
    gotoEdit(id) {
      let params = {
        id: id,
        keySearchMemo: this.keySearchMemo,
        currentPageMemo: this.currentPageMemo,
      };

      this.$router.push({
        path: 'createMemo',
        name: 'สร้าง Memo',
        params: params,
      });
    },
    async viewApprover(id) {
      this.approverList = await this.getApproverList(id);

      if (!Object.values(this.approverList)?.length) {
        await this.$store.dispatch('alerts/error', 'ไม่พบรายการผู้อนุมัติ');
        return false;
      }

      this.showApprover = true;
    },
    async getApproverList(id) {
      let params = {
        memo_id: id,
      };

      return await this.$store.dispatch('memo/getApproverList', params);
    },
    async getMemoList() {
      let params = {
        page: this.currentPageMemo,
        limit: this.limit,
        memo_search: this.keySearchMemo,
      };

      loading_start();
      if (this.mamoData?.length > 0) {
        return this.mamoData;
      } else {
        this.mamoData = await this.$store.dispatch('memo/getMemoList', params);
      }
      loading_close();

      return this.mamoData;
    },
    async handlePageChange(newPage) {
      await this.setMemoList();
      this.currentPageMemo = newPage;
    },
    async setMemoList() {
      let { pendingMemos, approvedMemos } = await this.getMemoList();

      this.memoList = pendingMemos?.['data']?.map((memo) => {
        memo.date = moment(memo.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        memo.created_at = moment(memo.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        memo.updated_at = moment(memo.updated_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        return memo;
      });

      this.totalrows = pendingMemos?.['rows'];

      this.approvedMemos = approvedMemos?.['data']?.map((memo) => {
        memo.date = moment(memo.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        memo.created_at = moment(memo.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        memo.updated_at = moment(memo.updated_at, 'YYYY-MM-DD HH:mm:ss').format(
          'DD/MM/YYYY HH:mm:ss',
        );
        return memo;
      });

      this.totalrows = approvedMemos?.['rows'];
    },
    gotoDelete() {
      this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
    },
    gotoPublish(memo_id) {
      this.$router.push({
        path: 'memoSetPublish',
        name: 'ตั้งค่า การเผยแพร่ Memo',
        params: {
          memo_id,
        },
      });
    },
    async preview(memoId) {
      try {
        loading_start();

        const response = await this.$store.dispatch('memo/getMemoDetail', {
          id: memoId,
        });

        this.memorandam = {
          toText: response.data.memoDetail.to_text,
          fromText: response.data.memoDetail.from_text,
          dateMemo: response.data.memoDetail.date,
          pageText: response.data.memoDetail.page,
          subjectText: response.data.memoDetail.subject,
          cc: response.data.memoDetail.cc,
        };

        const response_logo = await this.$store.dispatch('pdfs/getBranchLogo', {
          bch_id: response.data.memoDetail.bch_id,
        });
        this.branchLogo = response_logo[0].image;
        this.pdfme_template = response.data.memoDetail.memo_data;
        const title = `MEMORANDAM: ${response.data.memoDetail.subject}`;

        const baseTemplate = this.getBaseTemplate({
          branchLogo: this.branchLogo,
          memorandam: this.memorandam,
        });

        let counter = 0;
        const intervalId = setInterval(async () => {
          if (++counter > 5) return clearInterval(intervalId);

          const pdfmeEditor = this.$refs.pdfmeEditor;
          const designer = pdfmeEditor?.designer;
          if (designer) {
            clearInterval(intervalId);

            await designer.updateTemplate(this.pdfme_template);
            setTimeout(async () => {
              await pdfmeEditor.updateBaseTemplate(baseTemplate);
              await pdfmeEditor.generatePDF({ title });
            }, 100);
          }
        }, 500);
      } catch (error) {
        throw new Error(`Error generating preview: ${error}`);
      } finally {
        loading_close();
      }
    },
  },
};
</script>
<style lang="scss">
.memoList {
  .pointer {
    cursor: pointer;
  }

  .waitedit,
  .wait,
  .approve,
  .reject,
  .recheck {
    font-size: 18px;
  }

  .waitedit {
    color: #ffb300;
  }

  .wait {
    color: #005ed8;
  }

  .approve {
    color: #28a745;
  }

  .reject {
    color: #dc3545;
  }

  .recheck {
    color: #ff6a00;
  }

  .nowarp {
    white-space: nowrap;
  }

  i.md-icon.app {
    font-size: 35px !important;
    margin-left: 5px;

    &.waitedit {
      color: #ffb300;
    }

    &.wait {
      color: #005ed8;
    }

    &.approve {
      color: #28a745;
    }

    &.reject {
      color: #dc3545;
    }

    &.recheck {
      color: #ff6a00;
    }
  }
}
</style>
