<template>
  <div>
    <div class="file-grid">
      <div v-for="(file, index) in imageFiles" :key="index" class="file-item">
        <div class="image-preview">
          <img :src="file.file" :alt="file.name" />
          <div class="image-actions">
            <button @click="openImageInNewTab(file)" class="action-btn">
              Open
            </button>
            <button @click="downloadImage(file)" class="action-btn">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="file-grid mt-3">
      <div v-for="(file, index) in pdfFiles" :key="index" class="file-item">
        <div class="pdf-link" @click="previewPdf(file)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="26px"
            height="26px"
            fill="red"
          >
            <path
              d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"
            />
          </svg>
          <a> {{ file.name }} </a>
        </div>
      </div>

      <div
        v-for="(file, index) in unsupportedFiles"
        :key="index"
        class="file-item"
      >
        <div class="unsupported-file">
          Unsupported file type: {{ file.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilesViewer',
  props: {
    files: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    imageFiles() {
      return this.files.filter((file) => this.isImage(file));
    },
    pdfFiles() {
      return this.files.filter((file) => this.isPdf(file));
    },
    unsupportedFiles() {
      return this.files.filter(
        (file) => !this.isImage(file) && !this.isPdf(file),
      );
    },
  },
  methods: {
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png'];
      return imageExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );
    },
    isPdf(file) {
      return file.name.toLowerCase().endsWith('pdf');
    },
    openImageInNewTab(file) {
      const base64String = file.file;
      const byteCharacters = atob(base64String.split(',')[1]);
      const byteArray = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, '_blank');
    },
    downloadImage(file) {
      const base64String = file.file;
      const byteCharacters = atob(base64String.split(',')[1]);
      const byteArray = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = file.name;
      link.click();
    },
    async previewPdf(file) {
      const fileName = file.name;

      const base64String = file.file;

      const buffer = this.base64ToArrayBuffer(base64String);

      const pdfBlob = new Blob([buffer], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const embedContent = `
    <style>
      body {
        margin: 0;
        font-family: Arial, sans-serif;
      }
      .pdf-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .pdf-header {
        background-color: #333;
        color: white;
        text-align: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        box-sizing: border-box;
      }
      embed {
        flex: 1;
        border: none;
        width: 100%;
        height: 90vh;
        min-height: 500px;
      }
    </style>
    <html>
    <header>
    <title>${fileName}</title>
    </header>
    <body>
    <div class="pdf-container">
      <div class="pdf-header">${fileName}</div>
      <embed src="${pdfUrl}" type="application/pdf">
    </div>
    </body>
    </html>
  `;

      const x = window.open();
      x.document.open();
      x.document.write(embedContent);
      x.document.close();
    },

    base64ToArrayBuffer(base64) {
      const base64String = base64.replace(/^data:application\/pdf;base64,/, '');

      const binaryString = atob(base64String);

      const length = binaryString.length;
      const buffer = new ArrayBuffer(length);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < length; i++) {
        view[i] = binaryString.charCodeAt(i);
      }

      return buffer;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  max-width: 45vw;
  width: 100%;

  .file-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0.5rem;
    text-align: start;
    overflow: hidden;
  }

  .image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 300px;
    overflow: hidden;
    position: relative;
  }

  .image-preview img {
    width: 100%;
    height: 195px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }

  .image-actions {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    gap: 10px;
  }

  .action-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.75rem;
    border-radius: 4px;
  }

  .action-btn:hover {
    background-color: #0056b3;
  }

  .pdf-link {
    cursor: pointer;

    a {
      color: #007bff;
      text-decoration: none;
      font-weight: bold;

      font-size: 0.75rem;
      display: inline-block;
      margin-left: 8px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .unsupported-file {
    color: #dc3545;
    font-weight: bold;
  }
}
</style>
