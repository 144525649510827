<template>
  <div class="md-layout spacing">
    <div class="md-layout-item md-size-100">
      <div class="md-theme-default">
        <md-card-content>
          <b-container fluid>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-align-sm="left"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="my-1 text-right"
              >
              </b-col>
            </b-row>
            <b-row class="my-1">
              <b-col xs="12" sm="6" lg="6" class="my-1">
                <b-button variant="primary" @click="selectAllRows">
                  เลือกทั้งหมด
                </b-button>
                <b-button variant="danger" @click="clearSelected">
                  ล้าง
                </b-button>
              </b-col>
              <b-col xs="12" sm="6" lg="6" class="my-1 text-right">
                <b-button variant="danger" @click="disapprove">
                  Reject
                </b-button>
                <b-button class="mx-2" variant="warning" @click="recheck">
                  Recheck
                </b-button>
                <b-button variant="success" @click="approve">
                  Approve
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <div class="approve-all-tbl">
            <b-table
              id="my-table"
              :items="items"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :select-mode="selectMode"
              :busy="isBusy"
              ref="selectableTable"
              selectable
              show-empty
              small
              responsive
              hover
              @row-selected="onRowSelected"
            >
              <template #empty>
                <center>
                  <h4>ไม่มีข้อมูล</h4>
                </center>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(emp_name)="row">
                <p>
                  {{
                    `${row.item.empcreate_firstname_en} ${row.item.empcreate_lastname_en}`
                  }}
                </p>
              </template>

              <template #cell(detail)="row">
                <div
                  class="d-flex justify-content-around"
                  style="max-width: 150px;"
                >
                  <div
                    style=" white-space: nowrap; padding-left: 25px; padding-right: 100px;;"
                  >
                    <div style="text-align: left;">
                      <b>จาก : </b>{{ row.item?.from_text }}
                    </div>
                    <div style="text-align: left;">
                      <b>ถึง : </b>
                      <span>{{ row.item?.to_text }}</span>
                    </div>
                    <div style="text-align: left;">
                      <b>วันเริ่มใช้งาน : </b>
                      <span>{{ row.item?.date }}</span>
                    </div>
                    <div style="text-align: left;">
                      <b>CC : </b>
                      <span>{{ row.item?.cc }}</span>
                    </div>
                  </div>
                  <div style="margin: auto; white-space: nowrap;">
                    <b-button
                      variant="outline-primary"
                      @click="preview(row.item?.id)"
                    >
                      Preview PDF
                    </b-button>
                  </div>
                </div>
              </template>

              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span>&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span>&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
          </div>
        </md-card-content>
      </div>
    </div>
    <PdfmeEditor
      ref="pdfmeEditor"
      :contentTemplate="pdfme_template"
      @getPdfTemplate="getPdfTemplate($event)"
      @onMountedPdfme="onMountedPdfme"
      :hidden="true"
    >
    </PdfmeEditor>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading.js';
import PdfmeEditor from '../../../../components/Pdfme/RichEditor_Pdfme.vue';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';

export default {
  mixins: [MemoMixin],
  components: {
    PdfmeEditor: PdfmeEditor,
  },
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL,
      isBusy: false,
      selected: [],
      selectMode: 'multi',
      approve_st: 'approve',
      fields: [
        {
          key: 'selected',
          label: 'เลือก',
          tdClass: 'td-selected-approve',
          class: 'text-center',
        },
        {
          key: 'memo_name',
          label: 'ชื่อ',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'emp_name',
          label: 'จัดทำโดย',
          sortable: true,
          class: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: true,
          class: 'text-center',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      remark: '',
      pdfme_template: undefined,
      branchLogo: null,
      memorandam: {},
    };
  },

  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },

  methods: {
    onMountedPdfme() {},
    getPdfTemplate(template) {
      this.pdfme_template = template;
    },
    async preview(memoId) {
      try {
        loading_start();

        const response = await this.$store.dispatch('memo/getMemoDetail', {
          id: memoId,
        });

        this.memorandam = {
          toText: response.data.memoDetail.to_text,
          fromText: response.data.memoDetail.from_text,
          dateMemo: response.data.memoDetail.date,
          pageText: response.data.memoDetail.page,
          subjectText: response.data.memoDetail.subject,
          cc: response.data.memoDetail.cc,
        };

        const response_logo = await this.$store.dispatch('pdfs/getBranchLogo', {
          bch_id: response.data.memoDetail.bch_id,
        });
        this.branchLogo = response_logo[0].image;
        this.pdfme_template = response.data.memoDetail.memo_data;
        const title = `MEMORANDAM: ${response.data.memoDetail.subject}`;

        const baseTemplate = this.getBaseTemplate({
          branchLogo: this.branchLogo,
          memorandam: this.memorandam,
        });

        let counter = 0;
        const intervalId = setInterval(async () => {
          if (++counter > 5) return clearInterval(intervalId);

          const pdfmeEditor = this.$refs.pdfmeEditor;
          const designer = pdfmeEditor?.designer;
          if (designer) {
            clearInterval(intervalId);

            await designer.updateTemplate(this.pdfme_template);
            setTimeout(async () => {
              await pdfmeEditor.updateBaseTemplate(baseTemplate);
              await this.$refs.pdfmeEditor.generatePDF({
                title,
              });
            }, 100);
          }
        }, 500);
      } catch (error) {
        throw new Error(`Error generating preview: ${error}`);
      } finally {
        loading_close();
      }
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    approve() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          this.approve_st = 'approve';
          let res = await this.save_approve('approve');
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
            this.$emit('get_data');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    recheck() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to recheck the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'recheck',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'recheck';
          await loading_start();
          let res = await this.save_approve('recheck', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    disapprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to reject the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Reject',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'reject';
          await loading_start();
          let res = await this.save_approve('reject', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    async save_approve(status, remark = '') {
      const memoIds = this.selected.map((item) => item.id);
      const obj = { memoIds, status, remark };

      return await this.$store.dispatch('approvers/saveMemoApprovals', obj);
    },
  },
};
</script>

<style>
.td-selected-approve {
  width: 12% !important;
  vertical-align: middle !important;
  text-align: center;
  font-size: 2rem;
}

.show-file {
  width: 100%;
  height: 100%;
}

.footer-btn {
  width: 100%;
  text-align: right;
  margin: 10px 0;
  padding: 0px 50px;
}
</style>
