import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function applicantList(params) {
  const response = await axios.get(`${url}/v1/applicant-list`, { params });
  return response.data;
}

async function applicantAdd(params) {
  const response = await axios.post(`${url}/v1/applicant-add`, params);
  return response.data;
}

async function applicantDetail(params) {
  const response = await axios.get(`${url}/v1/applicant-detail`, { params });
  return response.data;
}

async function applicantApprovalsList(params) {
  const response = await axios.get(`${url}/v1/applicant-approvals-list`, {
    params,
  });
  return response.data;
}

async function applicantApprovalsSave(params) {
  const response = await axios.get(`${url}/v1/applicant-approvals-save`, {
    params,
  });
  return response.data;
}

export default {
  applicantList,
  applicantAdd,
  applicantDetail,
  applicantApprovalsList,
  applicantApprovalsSave,
};
