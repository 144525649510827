<template>
  <div
    class="upload-container"
    @dragover.prevent="handleDragOver"
    @drop.prevent="handleDrop"
  >
    <button
      class="btn btn-primary text-white h-12 rounded-xl px-5 py-2"
      @click="showModal = true"
    >
      Upload
    </button>

    <modal v-show="showModal" @close-modal="showModal = false">
      <template slot="header">
        <h4 class="modal-title">Upload Files</h4>
      </template>

      <template slot="body">
        <md-card class="upload upload-draggable relative">
          <label
            for="file-upload"
            class="upload-area"
            @click="triggerFileInput"
          >
            <div class="text-center">
              <div class="text-6xl mb-4 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 1364 960"
                  viewBox="0 0 1364 960"
                  width="200"
                  height="150"
                ></svg>
              </div>
              <p class="font-semibold">
                <span class="text-gray-800 dark:text-white">
                  Drop your files here, or
                </span>
                <span class="text-blue-500 cursor-pointer">
                  browse
                </span>
              </p>
              <p class="mt-1 font-semibold opacity-60 dark:text-white">
                through your machine
              </p>
            </div>
          </label>
          <input
            ref="fileInput"
            v-show="false"
            type="file"
            multiple
            :accept="types"
            @input="onFileChange"
          />
        </md-card>
        <div class="upload-file-list">
          <div
            class="upload-file d-flex w-full justify-content-between"
            v-for="(file, index) in files"
            :key="index"
          >
            <div class="d-flex items-center overflow-hidden">
              <div class="upload-file-thumbnail">
                <md-icon class="text-3xl heading-text">
                  insert_drive_file
                </md-icon>
              </div>

              <div class="upload-file-info overflow-auto pl-2 pr-2">
                <h6 class="upload-file-name text-md font-bold">
                  {{ file.name }}
                </h6>
                <span class="upload-file-size">{{
                  formatFileSize(file.size)
                }}</span>
              </div>
            </div>

            <md-button
              class="md-icon-button close-button button-press-feedback upload-file-remove ml-2"
              @click="removeFile(index)"
            >
              <md-icon>
                close
              </md-icon>
            </md-button>
          </div>
        </div>
      </template>

      <template slot="footer">
        <md-button
          class="md-info md-block"
          @click="$emit('files-upload', files)"
          >Upload
        </md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal } from '@/components';
import { encodeBase64, getFileSize } from '@/utils/file_manage';

export default {
  components: { Modal },
  props: {
    types: {
      type: String,
      default: '*/*',
    },
    maxSize: {
      type: Number,
      default: 2 * 1024 * 1024,
    },
  },
  data() {
    return {
      showModal: false,
      files: [],
    };
  },
  watch: {
    showModal(val) {
      if (!val) this.files = [];
    },
  },
  methods: {
    async addFiles(fileList) {
      const newFiles = Array.from(fileList).filter((file) => {
        return this.validateFile(file);
      });

      for (const file of newFiles) {
        const processedFile = await this.processFile(file);
        this.files.push(processedFile);
      }
    },
    async processFile(file) {
      const base64 = await encodeBase64(file);

      return {
        name: file.name,
        size: file.size,
        type: file.type,
        uint8array: base64,
      };
    },

    validateFile(file) {
      if (!this.isValidFileType(file, this.fileTypes)) {
        this.$store.dispatch(
          'alerts/error',
          `File type not allowed: ${file.name}`,
        );
        return false;
      }

      if (file.size > this.maxSize) {
        this.$store.dispatch(
          'alerts/error',
          `File size exceeds limit (2MB): ${file.name}`,
        );
        return false;
      }

      return true;
    },
    triggerFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    async onFileChange(event) {
      await this.addFiles(event.target.files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async handleDrop(event) {
      await this.addFiles(event.dataTransfer.files);
    },
    handleDragOver(event) {
      event.dataTransfer.dropEffect = 'copy';
    },
    isValidFileType(file) {
      if (this.types === '*/*') return true;

      const allowedTypes = this.types.split(',');

      return allowedTypes.some((type) => {
        if (type.startsWith('.')) {
          return file.name.endsWith(type);
        }
        return file.type === type;
      });
    },
    formatFileSize(size) {
      return getFileSize(size);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  .modal-container {
    min-width: 550px;
    max-width: max-content;
  }

  .modal-header {
    border-bottom: 0 !important;
  }

  .modal-footer {
    border-top: 0 !important;

    .md-button {
      border-radius: 10px;
    }
  }
}

.upload {
  border: 2px dashed #cbd5e1;
  border-radius: 8px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #f5f5f5 !important;
  position: relative;
  overflow: hidden;
}

.upload-draggable {
  position: relative;
  border: 2px dashed #cbd5e1;
  border-radius: 8px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #f5f5f5;

  &:hover {
    border-color: #2563eb;
    background-color: #e0f3ff;
  }
}

.upload-file-list {
  margin-top: 16px;
  overflow-y: auto;
  max-height: 35vh;

  .upload-file-info {
    max-width: 98%;
    word-wrap: break-word;
    overflow: hidden;

    .upload-file-name {
      white-space: normal;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
}

.drag-hover {
  cursor: pointer;
  border-color: #2563eb;
  background-color: #e0f3ff;
}

.upload-area {
  cursor: pointer;
}

.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 520px) {
  .modal-mask {
    .modal-container {
      min-width: 92vw;
      max-width: 92vw;

      .modal-body {
        .upload-file-name {
          font-size: 0.75rem;
        }
      }
    }
  }
}
</style>
