import qs from 'qs';
import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

async function get_topics(params) {
  try {
    const response = await axios.post(`${url}/v1/aps_get_topics_all`, params);
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function get_topics_score(params) {
  try {
    const response = await axios.post(`${url}/v1/aps_get_topics_score`, params);
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function get_list_report(params) {
  try {
    const response = await axios.post(`${url}/v1/aps_get_list_report`, params);
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function appraisal_date_report(params) {
  try {
    const response = await axios.post(
      `${url}/v1/aps_get_month_year_report`,
      params,
    );
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function get_time_process(params) {
  try {
    const response = await axios.post(`${url}/v1/getTimeProcessed`, params);
    return response.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/v1/aps_appraisal_list`, options);
  return response.data;
}

async function save_appraisal(params) {
  try {
    const response = await axios.post(`${url}/v1/aps_insert_score`, params);
    return response.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function get_score_history(params) {
  try {
    const response = await axios.post(
      `${url}/v1/aps_get_score_history`,
      params,
    );
    return response.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function get_score_history_log(params) {
  try {
    const response = await axios.post(
      `${url}/v1/aps_get_score_history_log`,
      params,
    );
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

async function appraisal_branches(params) {
  try {
    const response = await axios.post(
      `${url}/v1/aps_appraisal_branches`,
      params,
    );
    return response.data.data;
  } catch (error) {
    return { status: false, msg: 'erorr' };
  }
}

export default {
  get_topics,
  get_topics_score,
  save_appraisal,
  list,
  get_score_history,
  get_list_report,
  appraisal_date_report,
  get_time_process,
  get_score_history_log,
  appraisal_branches,
};
