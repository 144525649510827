<template>
  <div class="md-layout spacing appv-applicant">
    <div class="md-layout-item md-size-100">
      <div class="md-theme-default">
        <md-card-content>
          <div class="approve-all-tbl">
            <div class="d-flex justify-content-between p-4">
              <md-field class="mx-2">
                <label for="marital_st">สาขา</label>
                <md-select v-model="filters.branch_id" name="branch_id">
                  <md-option :key="'null-branch'" :value="''">
                    SHOW ALL BRANCHES
                  </md-option>
                  <md-option
                    v-for="item in branchData"
                    :key="item.id + '-branch'"
                    :value="item.id"
                  >
                    {{ item?.br_nameen }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <label for="marital_st">แผนก</label>
                <md-select
                  class="mx-2"
                  v-model="filters.dep_id"
                  name="dep_id"
                  v-if="positionsData?.length > 0"
                >
                  <md-option :value="''">
                    SHOW ALL DEPARTMENTS
                  </md-option>
                  <md-option
                    v-for="(item, index) in departmentsData"
                    :key="`${index}-${item?.id}-dep_id`"
                    :value="item?.id"
                  >
                    {{ item?.dep_nameen }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field class="mx-2">
                <label for="marital_st">ตำแหน่ง</label>
                <md-select
                  v-model="filters.pos_id"
                  name="pos_id"
                  v-if="positionsData?.length > 0"
                >
                  <md-option :value="''">
                    SHOW ALL POSITIONS
                  </md-option>
                  <md-option
                    v-for="(item, index) in positionsData"
                    :key="`${index}-${item?.id}-pos_id`"
                    :value="item?.id"
                  >
                    {{ item?.pos_nameen }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="d-flex justify-content-between">
              <div
                class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
                style="text-align: left"
              >
                <md-field>
                  <label>Items per page</label>
                  <md-select class="md-size-50" v-model="pagination.limit">
                    <md-option
                      v-for="(item, index) in limitOptions"
                      :key="index + 'limitList'"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div
                class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
                style="text-align: left"
              >
                <md-field>
                  <label>Search</label>
                  <md-input
                    class="md-size-50 searchID"
                    v-model="filters.search"
                  ></md-input>
                </md-field>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <md-button class="ml-4" @click="loadPage">Search</md-button>
            </div>
            <b-container fluid>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" class="my-1"></b-col>
                <b-col
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="my-1 text-right"
                >
                </b-col>
              </b-row>
              <b-row class="my-1">
                <b-col xs="12" sm="6" lg="6" class="my-1">
                  <b-button variant="primary" @click="selectAllRows">
                    เลือกทั้งหมด
                  </b-button>
                  <b-button
                    class="mx-2"
                    variant="danger"
                    @click="clearSelected"
                  >
                    ล้าง
                  </b-button>
                  <b-button variant="outline-primary" @click="preview()">
                    ดูเอกสารแนบ
                  </b-button>
                </b-col>
                <b-col xs="12" sm="6" lg="6" class="my-1 text-right">
                  <b-button variant="danger" @click="disapprove">
                    Reject
                  </b-button>
                  <b-button class="mx-2" variant="warning" @click="recheck">
                    Recheck
                  </b-button>
                  <b-button variant="success" @click="approve">
                    Approve
                  </b-button>
                </b-col>
              </b-row>
            </b-container>

            <b-table
              id="my-table"
              :items="appvList"
              :fields="fields"
              :filter="filters.search"
              :filter-included-fields="filterOn"
              :sort-by.sync="filters.sortBy"
              :sort-desc.sync="filters.sortDesc"
              :sort-direction="sortDirection"
              :select-mode="selectMode"
              :busy="isBusy"
              ref="selectableTable"
              selectable
              show-empty
              small
              responsive
              hover
              @row-selected="onRowSelected"
            >
              <template #empty>
                <center>
                  <h4>ไม่มีข้อมูล</h4>
                </center>
              </template>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(applicant_name)="row">
                <p>
                  {{ `${row.item.first_nameen} ${row.item.last_nameen}` }}
                </p>
              </template>

              <template #cell(detail)="row">
                <div
                  class="d-flex justify-content-around"
                  style="max-width: 50px;"
                >
                  <div style="margin: auto; white-space: nowrap;">
                    <b-button
                      variant="outline-primary"
                      @click="preview(row.item?.id)"
                    >
                      View Attachments
                    </b-button>

                    <modal v-show="showModal" @close-modal="showModal = false">
                      <template slot="header">
                        <h4 class="modal-title">View Attachments</h4>
                      </template>

                      <template slot="body">
                        <div>
                          <div
                            v-for="(attachment, index) in attachments"
                            :key="index"
                            class="person-card"
                          >
                            <div
                              class="person-header"
                              @click="togglePerson(index)"
                            >
                              <div class="person-info">
                                <span class="person-name">
                                  <span v-if="attachment?.employee?.emp_id">
                                    {{ attachment?.employee?.emp_id ?? '' }}:
                                  </span>
                                  {{ attachment?.employee?.first_nameen ?? '' }}
                                  {{ attachment?.employee?.last_nameen ?? '' }}
                                </span>
                              </div>
                              <button class="toggle-btn">
                                {{
                                  expandedIndex === index
                                    ? 'Collapse'
                                    : 'Expand'
                                }}
                              </button>
                            </div>
                            <div
                              v-if="expandedIndexes.includes(index)"
                              class="files-viewer-container"
                            >
                              <FilesViewer
                                :files="attachment.files"
                              ></FilesViewer>
                            </div>
                          </div>
                        </div>
                        <div v-if="attachments.length == 0">NO Attachments</div>
                      </template>
                    </modal>
                  </div>
                </div>
              </template>

              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span>&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span>&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            </b-table>
            <div class="d-flex justify-content-between">
              <p>
                Showing {{ pagination.start }} to {{ pagination.end }} of
                {{ pagination.totalItem }} entries
              </p>
              <pagination
                type="success"
                v-model="pagination.currentPage"
                :page-count="pagination.pageCount"
                @input="loadPage({ page: $event })"
              >
              </pagination>
            </div>
          </div>
        </md-card-content>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { loading_close, loading_start } from '@/utils/loading.js';
import { Modal, Pagination } from '@/components';
import FilesViewer from '@/components/Files/FilesViewer.vue';
import Vuex from 'vuex';
import employeeMixin from '@/mixins/employee-mixin';

export default {
  mixins: [employeeMixin],
  computed: {
    ...Vuex.mapState({
      branchData: (state) => state.branches.list,
    }),
    departmentsData() {
      const { branch_id } = this.filters || {};

      const allDepartments = Object.values(this.departments)
        .flatMap(Object.values)
        .reduce((acc, department) => {
          delete department?.branch_id;
          acc[department.id] = department;
          return acc;
        }, {});

      return branch_id
        ? this.departments[branch_id]
        : Object.values(allDepartments);
    },
    positionsData() {
      const { branch_id, dep_id } = this.filters || {};
      const allPositions = Object.values(this.positions).flatMap((branch) =>
        Object.values(branch).flat(),
      );

      if (!branch_id) return allPositions;
      const branch = this.positions[branch_id];
      if (!branch) return [];
      return dep_id ? branch[dep_id] || [] : Object.values(branch).flat();
    },
  },
  components: { FilesViewer, Modal, Pagination },
  data() {
    return {
      departments: {},
      positions: {},
      url: process.env.VUE_APP_API_BASE_URL,
      isBusy: false,
      selected: [],
      selectMode: 'multi',
      approve_st: 'approve',
      appvList: [...this.items],
      fields: [
        {
          key: 'selected',
          label: 'เลือก',
          tdClass: 'td-selected-approve',
          class: 'text-center',
        },
        {
          key: 'applicant_name',
          label: 'ชื่อ',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'emp_id',
          label: 'รหัสพนักงาน',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'branchs.br_nameen',
          label: 'สาขา',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'departments.dep_nameen',
          label: 'แผนก',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'positions.pos_nameen',
          label: 'ตำแหน่ง',
          sortable: true,
          sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'salary_amt',
          label: 'เงินเดือน',
          sortable: true,
          // sortDirection: 'desc',
          class: 'text-center',
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
          sortable: false,
          class: 'text-center',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      showModal: false,
      attachments: [],
      remark: '',
      filters: {
        branch_id: null,
        dep_id: null,
        pos_id: null,
        sortBy: null,
        sortDesc: null,
        search: null,
      },
      pagination: {
        currentPage: 1,
        pageCount: 1,
        limit: 15,
        start: 0,
        end: this.items?.length ?? 0,
        totalItem: this.items?.length ?? 0,
      },
      showListLimit: false,
      limitOptions: [2, 15, 20, 25, 30, 35, 40, 45, 50],
      expandedIndexes: [],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
  },
  async created() {
    loading_start();
    await this.getBranchData();

    this.departments = await this.getDepartmentKeyData();
    this.positions = await this.getPositionKeyData();

    loading_close();
  },
  methods: {
    togglePerson(index) {
      if (this.expandedIndexes.includes(index)) {
        this.expandedIndexes = this.expandedIndexes.filter(
          (item) => item !== index,
        );
      } else {
        this.expandedIndexes.push(index);
      }
    },
    async loadPage({ page = 1, ...restParams } = {}) {
      try {
        loading_start();

        const response = await this.$store.dispatch('applicant/appvList', {
          ...this.filters,
          ...restParams,
          page,
          limit: this.pagination.limit,
        });

        this.appvList = response.data;
        this.pagination.pageCount = response.last_page;
        this.pagination.currentPage = page;
        this.pagination.start = response.start;
        this.pagination.end = response.end;
        this.pagination.totalItem = response.total_items;
      } catch (error) {
        throw new Error('Error loading page:', error);
      } finally {
        loading_close();
      }
    },
    async preview(applicantIds) {
      try {
        if (!applicantIds) {
          if (this.selected.length <= 0) {
            await Swal.fire('กรุณาเลือกรายการ', '', 'info');
            return;
          }

          applicantIds = this.selected.map((item) => item.id);
        }
        loading_start();
        const response = await this.$store
          .dispatch('applicant/detail', {
            id: applicantIds,
          })
          .finally(() => loading_close());

        const data = Array.isArray(response.data)
          ? response.data
          : [response.data];
        this.attachments = Object.values(data).map((data) => ({
          files: data?.applicant_employee_files,
          employee: {
            first_nameen: data?.first_nameen,
            last_nameen: data?.first_nameen,
            nick_name: data?.nick_name,
            emp_id: data?.emp_id,
          },
        }));
        this.showModal = true;
      } catch (error) {
        throw new Error(`Error generating preview: ${error}`);
      } finally {
        loading_close();
      }
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    approve() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to approve the changes?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.value) {
          this.approve_st = 'approve';
          let res = await this.save_approve('approve');
          this.$emit('get_data');
          if (res?.status) {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    recheck() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to recheck the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'recheck',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'recheck';
          await loading_start();
          let res = await this.save_approve('recheck', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.st === 'success') {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    disapprove() {
      if (this.selected.length <= 0) {
        Swal.fire('กรุณาเลือกรายการ', '', 'info');
        return;
      }
      Swal.fire({
        title: 'Do you want to reject the changes?',
        icon: 'warning',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage('Please enter remark');
          }
          return inputValue;
        },
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        confirmButtonText: 'Reject',
      }).then(async (result) => {
        if (result.value) {
          this.remark = result.value;
          this.approve_st = 'reject';
          await loading_start();
          let res = await this.save_approve('reject', result.value);
          await loading_close();
          this.$emit('get_data');
          if (res.status) {
            await Swal.fire('Saved!', '', 'success');
          } else if (res.st === 'error') {
            await Swal.fire('Error!', '', 'error');
          }
        } else {
          return false;
        }
      });
    },
    async save_approve(status, remark = '') {
      const applicantIds = this.selected.map((item) => item.id);
      const obj = { applicantIds, status, remark };

      return await this.$store.dispatch('applicant/appvSave', obj);
    },
  },
  watch: {
    'items': {
      handler(value) {
        this.appvList = [...value];
      },
      deep: true,
      immediate: true,
    },
    'filters.branch_id'() {
      this.filters.dep_id = null;
      this.filters.pos_id = null;
    },
    'filters.dep_id'() {
      this.filters.pos_id = null;
    },
  },
};
</script>

<style lang="scss">
.appv-applicant {
  .modal-body {
    min-width: 65vw;
    max-height: 75vh;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
.appv-applicant {
  .modal-mask {
    cursor: default;
  }
}

.person-card {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  .person-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 0.75rem;
    cursor: pointer;

    .person-info {
      display: flex;
      flex-direction: column;

      .person-name {
        font-weight: bold;
        font-size: 1rem;
      }

      .person-detail {
        font-size: 0.875rem;
        color: #666;
      }
    }

    .toggle-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 0.75rem;
      border-radius: 4px;
    }

    .toggle-btn:hover {
      background-color: #0056b3;
    }
  }

  .files-viewer-container {
    padding: 1rem;
    background-color: #fff;
  }
}
</style>
