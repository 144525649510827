<template>
  <div class="appraisal-history">
    <md-dialog class="edit-data" :md-active.sync="showSetting" md-fullscreen>
      <md-dialog-title style="font-size: 1.5rem">
        ประเมินพนักงาน
      </md-dialog-title>
      <md-dialog-content v-if="emp_setting" style="font-size: 1.1rem">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100 md-small-size-100">
            รหัสพนักงาน : {{ emp_setting.emp_code }} <br />
            ชื่อพนักงาน :
            {{ emp_setting.firstname_th + ' ' + emp_setting.lastname_th }}
          </div>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <appraisalform
              ref="appraisalform"
              :emp_id="emp_setting.emp_id"
              :gfrom_date="emp_setting.from_date"
              :gto_date="emp_setting.to_date"
              :score_id="emp_setting.score_id"
              :disabled_status="true"
            />
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showSetting = false">
          ปิด
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon style="transform: scaleX(-1);">
                manage_accounts
              </md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="" style="text-align: right">
              <md-button @click="goBack()" class="md-primary md-dense">
                Back to appraisal
              </md-button>
            </div>
            <div class="md-layout">
              <div
                class="md-layout-item md-size-100 mt-2 md-small-size-100 md-alignment-left"
              >
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-70 mt-2 md-small-size-100 md-xsmall-size-100 md-alignment-left"
                  >
                    <md-autocomplete
                      v-model="staffSearchTerm"
                      :md-options="staffsSearchList"
                      @md-opened="searchStaffs"
                      @md-changed="searchStaffs"
                      @md-selected="selectStaff"
                      class="searchstaffs"
                    >
                      <label>ค้นหาพนักงาน</label>
                      <template
                        slot="md-autocomplete-item"
                        slot-scope="{ item }"
                      >
                        {{
                          item.emp_code +
                            ' ' +
                            item.firstname_en +
                            ' ' +
                            item.lastname_en
                        }}
                      </template>
                    </md-autocomplete>
                  </div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                  <div
                    class="md-layout-item md-size-15 mt-2 md-small-size-50 md-xsmall-size-50 md-alignment-left"
                  ></div>
                </div>
              </div>
            </div>

            <md-table
              :value="this.staffs"
              class="paginated-table table-striped table-hover"
              table-header-color="green"
            >
              <md-table-toolbar>
                <!-- Filter per page-->
                <md-field>
                  <label>Per page</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                <md-table-cell md-label="ลำดับ">
                  <span class="upfont">
                    {{
                      index +
                        1 +
                        (pagination.currentPage - 1) * pagination.perPage
                    }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="รหัสพนักงาน">
                  <span class="upfont">
                    {{ item.emp_code }}
                  </span>
                </md-table-cell>
                <md-table-cell md-label="ชื่อพนักงาน">
                  <span class="upfont">
                    {{ item.firstname_en }}
                    {{ item.lastname_en }}
                    ({{ item.firstname_th }} {{ item.lastname_th }})
                  </span>
                </md-table-cell>

                <md-table-cell md-label="วันที่ประเมิน">
                  <span class="upfont">
                    {{ convertToThaiDate(item.end_date) }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="Action">
                  <b-button variant="success" @click="getdata(item)">
                    รายละเอียด
                  </b-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vuex from 'vuex';
import AppraisalEmpForm from './AppraisalEmpForm.vue';
import Pagination from '@/components/Pagination';
import { loading_start, loading_close } from '@/utils/loading.js';
import employeeMixin from '@/mixins/employee-mixin';
import moment from 'moment';

export default {
  mixins: [employeeMixin],
  components: {
    pagination: Pagination,
    appraisalform: AppraisalEmpForm,
  },
  data() {
    return {
      emp_id: null,
      showSetting: false,
      emp_setting: null,
      staffs: [],
      staffList: [],
      staffSearchTerm: '',
      staffsSearchList: [],
      selected_emp: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 25, 50],
      },
      total: 0,
      emp_code: null,
      timeoutGetEmplist: null,
    };
  },
  async created() {
    if (!this.me?.employee_id) {
      await this.getProfileData();
    }

    this.emp_id = this.me.employee_id;

    this.staffList = await this.getListEmp();

    if (this.staffList.length > 0) {
      this.searchStaffs();
    }
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),

    from() {
      var start = 0;
      if (this.staffs.length > 0) {
        start = this.pagination.perPage * (this.pagination.currentPage - 1) + 1;
      }
      return start;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    pagination: {
      handler: 'getListDebounced',
      immediate: false,
      deep: true,
    },
    async emp_code() {
      this.getListDebounced();
    },

    async staffSearchTerm(val) {
      if (val.split(' ').length > 1) {
        val = val.split(' ')[0];
      }
      this.emp_code = val;
    },
  },
  //   computed: {
  //   },
  methods: {
    async getListDebounced() {
      if (this.timeoutGetEmplist != null) {
        clearTimeout(this.timeoutGetEmplist);
      }
      this.timeoutGetEmplist = setTimeout(async () => {
        this.staffList = await this.getListEmp();

        if (this.staffList.length > 0) {
          this.searchStaffs();
        }
      }, 800);
    },

    async getListEmp() {
      loading_start();
      var params = {
        emp_id: this.emp_id,
        ...{
          page: this.pagination.currentPage ? this.pagination.currentPage : {},
        },
        ...{ limit: this.pagination.perPage ? this.pagination.perPage : {} },
        ...{
          emp_code: this.emp_code ? this.emp_code : null,
        },
      };
      let staffList = await this.$store.dispatch(
        'appraisal/get_score_history',
        params,
      );
      this.total = staffList.total;
      this.staffsSearchList = Object.values(staffList.searchList);
      loading_close();

      return staffList.data;
    },

    async getdata(emp) {
      if (emp == null) {
        this.$store.dispatch('alerts/error', 'มีบางอย่างผิดพลาด');
        return false;
      }
      this.emp_setting = emp;
      this.showSetting = true;
    },

    async searchStaffs(searchTerm = '') {
      if (searchTerm == '') {
        searchTerm = this.staffSearchTerm;
      }
      this.staffs = await new Promise((resolve) => {
        window.setTimeout(() => {
          if (searchTerm == '') {
            resolve(this.staffList);
          } else {
            // กรองค่าตาม searchTerm
            const term = searchTerm.toLowerCase().split(' ')[0];
            const filteredArray = this.staffList.filter(
              (item) =>
                item.emp_code.toLowerCase().includes(term) ||
                item.firstname_th.toLowerCase().includes(term) ||
                item.lastname_th.toLowerCase().includes(term) ||
                item.firstname_en.toLowerCase().includes(term) ||
                item.lastname_en.toLowerCase().includes(term),
            );
            resolve(filteredArray); // คืนค่าผลลัพธ์ที่กรองแล้ว
          }
        }, 300);
      });
      if (searchTerm == '') {
        this.staffsSearchList = new Promise((resolve) => {
          var temp = this.staffsSearchList;
          resolve(temp);
        });
      }
    },

    selectStaff(c) {
      if (this.staffSearchTerm != '') {
        this.staffSearchTerm = c.emp_code;
      } else {
        this.staffSearchTerm = '';
      }
    },

    convertToThaiDate(dateString) {
      const [year, month, day] = dateString.split('-');
      const thaiYear = parseInt(year) + 543;
      return `${day}/${month}/${thaiYear}`;
    },

    async goBack() {
      let params = {};

      this.$router.push({
        path: 'appraisal',
        name: 'การประเมิน',
        params: params,
      });
    },
  },
};
</script>
