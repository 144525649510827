import service from '@/store/services/appraisal-service';

const state = {};

const mutations = {};

const actions = {
  async get_topics({ commit, dispatch }, params) {
    return await service.get_topics(params);
  },
  async get_topics_score({ commit, dispatch }, params) {
    return await service.get_topics_score(params);
  },

  async get_score_history({ commit, dispatch }, params) {
    return await service.get_score_history(params);
  },

  async get_score_history_log({ commit, dispatch }, params) {
    return await service.get_score_history_log(params);
  },

  async get_list_report({ commit, dispatch }, params) {
    return await service.get_list_report(params);
  },

  async appraisal_date_report({ commit, dispatch }, params) {
    return await service.appraisal_date_report(params);
  },

  async get_time_process({ commit, dispatch }, params) {
    return await service.get_time_process(params);
  },

  async save_appraisal({ commit, dispatch }, params) {
    return await service.save_appraisal(params);
  },

  async appraisal_branches({ commit, dispatch }, params) {
    return await service.appraisal_branches(params);
  },

  list({ commit, dispatch }, params) {
    return service.list(params).then((list) => {
      // commit('SET_LIST', list);
      return list;
    });
  },
};

const getters = {};

const appraisal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default appraisal;
