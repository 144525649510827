import pako from 'pako';

class FileManage {
  /* START FUNCTION: encodeBase64 */
  /**
   * Encodes one or more files to Base64 format.
   * @param {File|File[]} files - A single file or an array of files.
   * @returns {Promise<string|string[]>} - A promise resolving to a Base64 string for one file or an array for multiple files.
   */
  async encodeBase64(files) {
    if (!files || (!Array.isArray(files) && !(files instanceof File))) {
      throw new Error('Input must be a File or an array of File objects.');
    }

    try {
      const fileManage = new FileManage();
      if (Array.isArray(files)) {
        return Promise.all(files.map((file) => fileManage.convert(file)));
      }

      return fileManage.convert(files);
    } catch (error) {
      throw new Error(
        'An error occurred during Base64 encoding: ' + error.message,
      );
    }
  }

  convert(file) {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File)) {
        reject(new Error('Each item must be a File instance.'));
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read file data.'));
        }
      };

      reader.onerror = () => {
        reject(new Error('An error occurred while reading the file.'));
      };

      try {
        reader.readAsDataURL(file);
      } catch (error) {
        reject(new Error('Failed to initiate file reading: ' + error.message));
      }
    });
  }

  /* START FUNCTION: getFileSize */
  /**
   * Calculates the size of a file or data in a human-readable format.
   * Handles Blob, Base64 encoded strings, and pre-calculated size.
   * @returns {string} - The size of the file in a readable format (bytes, KB, MB).
   * @param input
   */
  getFileSize(input) {
    const fileManage = new FileManage();
    if (input instanceof Blob || input instanceof File) {
      return fileManage.formatSize(input.size); // Use formatSize directly
    } else if (typeof input === 'number') {
      return fileManage.formatSize(input);
    } else if (Array.isArray(input)) {
      return fileManage.formatSize(input.length);
    } else {
      throw new Error('Input must be a File, Blob, Base64 string, or number.');
    }
  }

  /**
   * Formats the file size into a human-readable format.
   * @param {number} size - The size of the file in bytes.
   * @returns {string} - The formatted size (e.g., "2 KB", "5 MB").
   */
  formatSize(size) {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    else return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }

  /* START LIBRARY: PAKO */
  /**
   * Compresses one or more files using the pako library.
   * @param {Uint8Array[]|string[]} data - An array of data to be compressed, each entry can be a Uint8Array or a string.
   * @returns {Uint8Array[]|Uint8Array} - An array or single compressed data based on input type.
   */
  compress(data) {
    if (!data || data.length === 0) {
      throw new Error('No data provided for compression.');
    }

    try {
      if (!Array.isArray(data)) {
        return pako.deflate(data);
      }

      return data.map((item) => {
        return pako.deflate(item);
      });
    } catch (error) {
      throw new Error('Compression failed: ' + error.message);
    }
  }

  /**
   * Decompresses one or more compressed files using the pako library.
   * @param {Uint8Array[]|Uint8Array} compressedData - An array or single compressed data to be decompressed.
   * @returns {string[]|string} - An array or single decompressed data as string based on input type.
   */
  decompress(compressedData) {
    if (!compressedData || compressedData.length === 0) {
      throw new Error('No data provided for decompression.');
    }

    try {
      if (!Array.isArray(compressedData)) {
        return pako.inflate(compressedData, { to: 'string' });
      }

      return compressedData.map((item) => {
        return pako.inflate(item, { to: 'string' });
      });
    } catch (error) {
      throw new Error('Decompression failed: ' + error.message);
    }
  }
}

export const {
  encodeBase64,
  getFileSize,
  compress,
  decompress,
} = new FileManage();
