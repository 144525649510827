import service from '@/store/services/canteen-service';

const state = {};

const mutations = {};

const actions = {
  async getCanteenReport({ commit, dispatch }, params) {
    let data = await service.getCanteenReport(params);
    return data;
  },
};

const getters = {};

const canteen = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default canteen;
