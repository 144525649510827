import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    header: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };
  return axios.get(`${url}/v1/positions`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function listKey(params) {
  return axios.get(`${url}/v1/positions-key`, { params }).then((response) => {
    return {
      list: response.data.data,
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios.get(`${url}/v1/positions/${id}`, options).then((response) => {
    let position = jsona.deserialize(response.data);
    delete position.links;
    return position;
  });
}

function add(position) {
  const payload = jsona.serialize({
    stuff: position,
    includeNames: null,
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .post(`${url}/v1/positions`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(position) {
  const payload = jsona.serialize({
    stuff: position,
    includeNames: [],
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  return axios
    .patch(`${url}/v1/positions/${position.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  listKey,
  get,
  add,
  update,
};
