<template>
  <div>
    <div
      class="d-flex flex-column flex-md-row justify-content-between align-items-center gap-4"
    >
      <div><h3>Attached Documents</h3></div>
      <div class="d-flex align-items-center gap-2">
        <upload-files
          ref="upload_file"
          :types="'image/jpeg,image/png,.pdf'"
          @files-upload="filesUpload"
        ></upload-files>
      </div>
    </div>
    <div class="w-100 pt-4 px-5">
      <div
        class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xxl-5 gap-3 w-100"
      >
        <!-- File 1 -->
        <div class="p-1" v-for="attachment in attachments" :key="attachment.id">
          <div class="card shadow-sm border-0 bg-white rounded-2xl">
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div class="d-flex gap-3">
                <div class="text-danger fs-3 pr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 287.82 384"
                    width="40"
                    height="40"
                  >
                    <path
                      fill="#e62335"
                      d="M652.52 792.45h192s50.06.1 49-59.26 0-210.58 0-210.58H785.16l-1-114.16H652.52s-45.94 1.52-46.52 53 0 284.71 0 284.71 4.19 44.97 46.52 46.29z"
                      transform="translate(-605.74 -408.45)"
                    ></path>
                    <path
                      fill="#ee656c"
                      d="M178.39 0L287.82 114.16 179.42 114.16 178.39 0z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <div class="fw-bold">{{ attachment.name }}</div>
                  <span class="text-muted fs-6">
                    {{ getFileSize(attachment?.size ?? attachment?.file) }}
                  </span>
                </div>
              </div>
              <div class="dropdown">
                <button
                  class="btn btn-link text-decoration-none p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                  >
                    <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                    <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                    <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action 1</a></li>
                  <li><a class="dropdown-item" href="#">Action 2</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFiles from '@/components/Files/FilesUpload.vue';
import { getFileSize } from '@/utils/file_manage';
import moment from 'moment';

export default {
  components: {
    UploadFiles,
  },
  props: {
    applicantId: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async filesUpload(files) {
      this.$refs.upload_file.showModal = false;
      this.attachments.push(...files);
      this.$emit('input', files);
    },
    getFileSize(file) {
      return getFileSize(file);
    },
  },
};
</script>

<style lang="scss">
.spacing {
  padding-bottom: 1rem;
}

.uploaded-files ul {
  list-style-type: none;
  padding: 0;
}

.uploaded-files li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: red;
}
</style>
