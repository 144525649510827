<template>
  <div class="hiring-applicant">
    <md-card>
      <md-card-header
        class="md-card-header-icon md-card-header-green add-applicant"
      >
        <div class="card-icon">
          <md-icon>list</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="gotoCreate()" class="md-success md-dense">
            เพิ่มผู้สมัคร
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div
          class="md-layout-item md-size-5 md-medium-size-10 md-small-size-10 md-xsmall-size-100"
          style="text-align: left"
        >
          <label style="margin-top: 1.3rem; text-align: right">
            ค้นหา:
          </label>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Search</label>
            <md-input
              class="md-size-50 searchID"
              v-model="keySearchApplicant"
              @keyup.enter="setApplicantList()"
            ></md-input>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-30 md-medium-size-40 md-small-size-40 md-xsmall-size-85"
          style="text-align: left"
        >
          <md-field>
            <label>Limit</label>
            <md-select class="md-size-50" v-model="limit">
              <md-option
                v-for="(item, index) in limitList"
                :key="index + 'limitList'"
                :value="item"
              >
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
          style="text-align: left"
        >
          totalrows: {{ totalrows }} currentPage: {{ currentPage }}
          <b-pagination
            :value="currentPage"
            :total-rows="totalrows"
            :per-page="limit"
            @input="handlePageChange"
          />
          <tabs
            :tab-name="['Pending Approval']"
            :tab-icon="['update']"
            :tab-active="0"
            nav-pills-icons
            plain
            color-button="warning"
          >
            <!-- here you can add your content for tab-content -->
            <template slot="tab-pane-1">
              <md-card>
                <b-table-simple responsive>
                  <b-thead>
                    <b-tr>
                      <b-th>No.</b-th>
                      <b-th sticky-column>Name</b-th>
                      <b-th>Branch Name</b-th>
                      <b-th>Department Name</b-th>
                      <b-th>Positions Name</b-th>
                      <b-th>Status</b-th>
                      <b-th>Create Date</b-th>
                      <b-th>Update Date</b-th>
                      <!--                      <b-th>CreateBy / UpdateBy</b-th>-->
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(applicant, index) in applicantList"
                      :key="index + 'applicantList'"
                    >
                      <b-td>{{ index + 1 + (currentPage - 1) * limit }}</b-td>
                      <b-th sticky-column>
                        {{ applicant?.first_nameen }}
                        {{ applicant?.last_nameen }}
                      </b-th>
                      <b-td>{{ applicant.branchs?.br_nameen }}</b-td>
                      <b-td>{{ applicant.departments?.dep_nameen }}</b-td>
                      <b-td>{{ applicant.positions?.pos_nameen }}</b-td>
                      <b-td class="nowarp" @click="viewApprover(applicant.id)">
                        <span
                          v-if="applicant.status_approve == 0"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">รออนุมัติ</span>
                          <span v-else>WAIT APPROVE</span>
                        </span>
                        <span
                          v-if="applicant.status_approve == -1"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">แบบร่าง</span>
                          <span v-else>DRAFT</span>
                        </span>
                        <span
                          v-if="applicant.status_approve == 2"
                          class="waitapprove"
                        >
                          <span v-if="lang == 'th'">ตรวจสอบใหม่</span>
                          <span v-else>RECHECK</span>
                        </span>
                        <span
                          v-else-if="applicant.status_approve == 3"
                          class="approved"
                        >
                          <span v-if="lang == 'th'">อนุมัติ</span>
                          <span v-else>APPROVED</span>
                        </span>
                      </b-td>
                      <b-td>{{ applicant.created_at }}</b-td>
                      <b-td>{{ applicant.updated_at }}</b-td>
                      <!-- <b-td class="nowarp">
                        <span>
                          CreateBy:
                          {{ applicant.empcreate_emp_id }}
                          {{
                            `${applicant.empcreate_titlename_en} ${applicant.empcreate_firstname_en} ${applicant.empcreate_lastname_en}`
                          }}
                          {{
                            `${applicant.empcreate_titlename_th} ${applicant.empcreate_firstname_th} ${applicant.empcreate_lastname_th}`
                          }}
                        </span>
                        <span
                          v-if="
                            applicant.empupdate_emp_id &&
                              applicant.empcreate_emp_id !=
                                applicant.empupdate_emp_id
                          "
                        >
                          <br />
                          {{ applicant.empupdate_emp_id }}
                          {{
                            `${applicant.empupdate_titlename_en} ${applicant.empupdate_firstname_en} ${applicant.empupdate_lastname_en}`
                          }}
                          {{
                            `${applicant.empupdate_titlename_th} ${applicant.empupdate_firstname_th} ${applicant.empupdate_lastname_th}`
                          }}
                        </span>
                      </b-td> -->
                      <b-td>
                        <b-button
                          variant="warning"
                          class="mr-2"
                          @click="gotoEdit(applicant.id)"
                          v-if="applicant.status_approve === -1"
                        >
                          Edit
                        </b-button>
                        <b-button
                          variant="warning"
                          class="mr-2"
                          @click="gotoEdit(applicant.id)"
                          v-if="applicant.status_approve === 2"
                        >
                          Recheck
                        </b-button>
                        <b-button
                          variant="primary"
                          class="mr-2"
                          v-if="applicant.status_approve !== -1"
                        >
                          Preview
                        </b-button>
                        <b-button
                          variant="danger"
                          class="ml-2"
                          @click="gotoDelete(applicant.id)"
                          v-if="applicant.status_approve == -1"
                        >
                          Delete
                        </b-button>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="!applicantList?.length">
                      <b-td :colspan="9" class="text-center">No Data</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </md-card>
            </template>
          </tabs>
        </div>
      </md-card-content>
    </md-card>
    <md-dialog>
      <md-dialog-title>Approver</md-dialog-title>
      <md-dialog-content>
        <md-list>
          <md-list-item
            v-for="(app, index) in approverList"
            :key="index + 'approverList'"
          >
            {{ app.emp_first_name + ' ' + app.emp_last_name }}
            <md-icon>check</md-icon>
          </md-list-item>
        </md-list>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click="showApprover = false">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { Tabs } from '@/components';

export default {
  components: { Tabs },
  data() {
    return {
      keySearchApplicant: '',
      limit: 10,
      limitList: [10, 20, 30, 40, 50],
      currentPage: 1,
      totalrows: 0,
      applicantList: this.$route.params.applicantList || [],
      approverList: [],
      lang: 'en',
      stateNext: [],
    };
  },
  async beforeCreate() {
    const response = await this.$store.dispatch('applicant/list', {
      sortBy: 'status_approve',
      sortDesc: true,
    });
    if (response.status) {
      this.applicantList = response.data;
    }
  },
  methods: {
    gotoCreate() {
      this.$router.push('applicant-add');
    },
    setApplicantList() {
      // Fetch data based on keySearchApplicant, limit, and currentPage
      // Populate applicantList and totalrows
    },
    handlePageChange(page) {
      this.currentPage = page;

      this.setApplicantList();
    },

    gotoEdit(id) {
      this.$router.push({
        path: 'applicant-add',
        name: 'เพิ่มผู้สมัคร',
        params: { id },
      });
    },
    gotoDelete(id) {
      // Perform delete operation
    },
    viewApprover(id) {
      // View approver details
    },
  },
};
</script>

<style scoped>
/* Add custom styles here */
</style>
