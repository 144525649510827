import service from '@/store/services/positions-service';

const state = {
  list: {},
  listKey: {},
  position: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_LIST_KEY: (state, list) => {
    state.listKey = list;
  },
  SET_RESOURCE: (state, position) => {
    state.position = position;
  },
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit('SET_LIST', list);
    });
  },

  listKey({ commit, dispatch }, params) {
    return service.listKey(params).then(({ list, meta }) => {
      commit('SET_LIST_KEY', list);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((position) => {
      commit('SET_RESOURCE', position);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((position) => {
      commit('SET_RESOURCE', position);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((position) => {
      commit('SET_RESOURCE', position);
    });
  },
};

const getters = {
  list: (state) => state.list,
  position: (state) => state.position,
  dropdown: (state) => {
    return state.list.map((position) => ({
      id: position.id,
      name: position.pos_nameen,
    }));
  },
};

const positions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default positions;
