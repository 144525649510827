import service from '@/store/services/Hiring/applicant-service';

const state = {};

const mutations = {};

const actions = {
  async list({ commit, dispatch }, params) {
    return await service.applicantList(params);
  },
  async add({ commit, dispatch }, params) {
    return await service.applicantAdd(params);
  },
  async detail({ commit, dispatch }, params) {
    return await service.applicantDetail(params);
  },
  async appvList({ commit, dispatch }, params) {
    return await service.applicantApprovalsList(params);
  },
  async appvSave({ commit, dispatch }, params) {
    return await service.applicantApprovalsSave(params);
  },
};

const getters = {};

const memo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default memo;
