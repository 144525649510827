import axios from 'axios';

const url_api = process.env.VUE_APP_API_BASE_URL;
const url_service = process.env.VUE_APP_API_SERVICE_URL;

async function getCanteenReport(params) {
  const response = await axios.get(
    `${url_service}/canteen/getTimeScanCanteen`,
    { params },
  );
  return response.data;
}

export default {
  getCanteenReport,
};
