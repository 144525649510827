<template>
  <div class="md-layout applicant-add">
    <!-- Progress bar -->
    <vue-progress-bar></vue-progress-bar>
    <div class="md-layout-item md-small-size-100 mx-auto">
      <simple-wizard
        ref="wizard"
        @tabIndex="tabIndex = $event"
        @tabsLength="tabsLength = $event"
      >
        <template slot="header">
          <h3 class="title">
            ข้อมูลพนักงาน
          </h3>
          <div class="text-right">
            <md-button @click="goBack()" class="md-primary md-dense">
              Back to List
            </md-button>
          </div>
        </template>
        <wizard-tab>
          <template slot="label">ข้อมูลส่วนตัว</template>
          <personal-form ref="step1" :user="user"></personal-form>
        </wizard-tab>
        <!--  -->
        <wizard-tab>
          <template slot="label">ข้อมูลพนักงาน</template>
          <staff-form v-if="is_getData" :user="user" :branchs="branchs">
          </staff-form>
        </wizard-tab>
        <!--   -->
        <wizard-tab>
          <template slot="label">เอกสารแนบ</template>
          <attachment-form
            ref="step3"
            v-model="attachments.inserts"
            v-if="is_getData"
            :attachments="attachments.data"
          ></attachment-form>
        </wizard-tab>
        <template slot="footer">
          <div class="d-flex justify-content-end w-100">
            <div class="mr-2">
              <md-button
                :disabled="tabIndex == 0"
                @click.native="$refs.wizard.prevTab"
                class="btn-previous"
              >
                Previous
              </md-button>

              <md-button
                :disabled="
                  tabIndex > tabsLength - 1 || tabIndex == tabsLength - 1
                "
                @click.native="$refs.wizard.nextTab"
                class="btn-next md-primary"
              >
                Next
              </md-button>
            </div>

            <div class="ml-2">
              <md-button
                @click="save(user.status_approve == 2 ? 'recheck' : 'draft')"
                class="btn-draft md-primary"
              >
                Draft
              </md-button>
              <md-button
                :disabled="tabIndex != tabsLength - 1"
                class="md-success"
                @click="save('approve')"
              >
                Save
              </md-button>
            </div>
          </div>
        </template>
      </simple-wizard>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import { SimpleWizard, WizardTab } from '@/components';
import formMixin from '@/mixins/form-mixin';
import employeeMixin from '@/mixins/employee-mixin';
import Vuex from 'vuex';
import PersonalForm from '@/pages/Dashboard/Pages/Admin/UserManagement/Forms/PersonalForm.vue';
import StaffForm from '@/pages/Dashboard/Pages/Admin/UserManagement/Forms/StaffForm.vue';
import AttachmentForm from '@/pages/Dashboard/Pages/Admin/EmployeeManagement/HiringManagement/From/AttachmentForm.vue';
import { loading_close, loading_start } from '@/utils/loading';
import { decompress } from '@/utils/file_manage';

export default {
  mixins: [formMixin, employeeMixin],

  data() {
    return {
      attachments: { data: [], inserts: [], deletes: [] },
      user: {
        address: null,
        bank_name: null,
        bank_number: null,
        birth_date: null,
        branchs: {
          id: null,
          type: 'branchs',
        },
        canleave: true,
        canleave_msg: 'เปิดสิทธิ์',
        departments: {
          id: null,
          type: 'departments',
        },
        districts: {
          id: null,
          type: 'districts',
        },
        email: '',
        emp_id: null,
        finish_date: null,
        first_nameen: null,
        first_nameth: null,
        id: undefined,
        idcard: null,
        last_nameen: null,
        last_nameth: null,
        marital_st: null,
        nationality: null,
        nick_name: null,
        password: null,
        password_confirmation: null,
        phone: null,
        positions: {
          id: null,
          type: 'positions',
        },
        private_code: '',
        provinces: {
          id: null,
          type: 'provinces',
        },
        race: null,
        religion: null,
        salarys: {
          type: 'salarys',
          salary_amt: 0,
          status: 'active',
        },
        sex: null,
        staff_st_msg: 'ทำงาน',
        stafftype: null,
        start_date: null,
        status: true,
        subdistricts: {
          id: null,
          type: 'subdistricts',
        },
        title_nameen: null,
        title_nameth: null,
        type: 'employees',
        weekends: {
          id: null,
          type: 'weekends',
        },
        workingtimes: {
          type: 'employee_has_working',
          id: null,
          working_id: null,
          start_date: null,
          status: 'active',
          work_type: 'major',
        },
        zipcode: null,
        approval_level: -1,
        status_approve: -1,
      },
      branchs: [],
      tabIndex: 0,
      tabsLength: 3,
    };
  },
  components: {
    AttachmentForm,
    PersonalForm,
    StaffForm,
    SimpleWizard,
    WizardTab,
  },
  async created() {
    const params = this.$route.params;

    if (params?.id) {
      try {
        const response = await this.$store.dispatch('applicant/detail', {
          id: params.id,
        });
        if (response?.data) {
          Object.entries(response.data).forEach(([key, value]) => {
            if (key in this.user && value) {
              this.user[key] = value ?? null;
            }
            if (key === 'applicant_employee_files') {
              this.attachments.data = value?.map((file) => file) || [];
            }
            if (
              key === 'start_date' ||
              key === 'finish_date' ||
              key === 'birth_date'
            ) {
              let date = this.user[key];
              if (!date || isNaN(Date.parse(date))) {
                this.user[key] = null;
              } else {
                this.user[key] = moment(date).format('DD/MM/YYYY');
              }
            }
          });
        }
      } catch (error) {
        throw new Error('Error fetching applicant details:', error);
      }
    }

    this.start();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      branchData: (state) => state.branches.list,
      departData: (state) => state.departments.list,
    }),
    is_getData() {
      if (this.branchData && this.departData) {
        this.finish();
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {},
  methods: {
    async save(status) {
      try {
        loading_start();
        this.start();
        const user_insert = { ...this.user };
        user_insert.start_date = moment(
          user_insert.start_date,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD');
        user_insert.birth_date = moment(
          user_insert.birth_date,
          'DD/MM/YYYY',
        ).format('YYYY-MM-DD');
        if (user_insert.finish_date != null) {
          user_insert.finish_date = moment(
            user_insert.finish_date,
            'DD/MM/YYYY',
          ).format('YYYY-MM-DD');
        }
        user_insert.status = status;
        // if (status == 'approve') {
        //   user_insert.approval_level = 1;
        //   user_insert.status_approve = 0;
        // }

        user_insert.privatecode = localStorage.getItem('privatecode');
        const response = await this.$store.dispatch('applicant/add', {
          applicant: user_insert,
          attachments: {
            inserts: this.attachments.inserts,
            deletes: this.attachments.deletes,
          },
        });

        this.finish();
        if (response?.status) {
          await this.$store.dispatch('alerts/success', response.message);

          if (status == 'approve') {
            this.goBack();
          }
          this.user.id = response.data.applicant.id;
        } else {
          await this.$store.dispatch('alerts/error', response.message);
        }
        loading_close();
        // this.goBack();
      } catch (e) {
        loading_close();
        if (e?.response?.data?.errors?.[0]) {
          await this.$store.dispatch('alerts/error', e.response.data.errors[0]);
        } else {
          await this.$store.dispatch(
            'alerts/error',
            'Oops, something went wrong!',
          );
        }
      }
    },
    goBack() {
      this.$router.push({ path: 'applicant-list' });
    },
    async setBranchesDropdown() {
      this.branchs = this.$store.getters['branches/dropdown'];
      this.finish();
    },
    start() {
      this.$Progress.start();
    },
    finish() {
      this.$Progress.finish();
    },
    fail() {
      this.$Progress.fail();
    },
  },
};
</script>
<style lang="scss">
.applicant-add {
  .spacing {
    padding-bottom: 1rem;
    justify-content: center;
  }

  .tab-content {
    width: 100%;
  }

  .text-right {
    display: flex;
  }

  // Fix Component Wizard.vue
  .wizard-navigation .nav-link {
    &.active,
    &.checked {
      cursor: pointer;
      width: 100%;
      text-align: left;
    }
  }

  // Fix Component StaffForm.vue
  .md-card-content {
    .md-layout-item.md-size-25.mt-2.md-small-size-100 {
      min-width: 20%;
      max-width: 20%;
    }
  }
}
</style>
