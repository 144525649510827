import $ from 'jquery';
import Vuex from 'vuex';

export default {
  data() {
    return {
      //isLoading: false,
      //apiValidationErrors: {}
    };
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      branchData: (state) => state.branches.list,
      departData: (state) => state.departments.list,
      depKeyData: (state) => state.departments.listKey,
      posKeyData: (state) => state.positions.listKey,
      workingData: (state) => state.workingtimes.list,
      provinceData: (state) => state.provinces.province,
      districtData: (state) => state.provinces.district,
      subdistData: (state) => state.provinces.subdist,
      mepermissions: (state) => state.profile.mepermissions,
    }),
  },
  methods: {
    /* Get employee api from backend  */
    async getEmployeeData(branchid, userid) {
      let params = {
        filter: {
          ...{ igr_emp: '99999999' },
          ...{ status: 'active' },
          ...(branchid ? { branch: branchid } : {}),
          ...(userid ? { id: userid } : {}),
        },
      };
      await this.$store.dispatch('staffs/list', params);
    },

    /* Get branch api from backend */
    async getBranchData() {
      if (!this.branchData || !this.branchData.length) {
        if (!this.me) {
          await this.getProfileData();
        }
        let params = {
          filter: {
            ...(this.me?.employees?.branchs?.company_id
              ? { company_id: this.me?.employees?.branchs?.company_id }
              : {}),
          },
        };
        return await this.$store.dispatch('branches/list', params);
      }
      return this.branchData;
    },

    /* Get profile api from backend */
    async getProfileData(id) {
      if (!this.me) {
        try {
          await this.$store.dispatch('profile/me');
        } catch (error) {
          await this.$store.dispatch('logout');
        }
      }

      if (id) {
        let params = {
          ...{ emp_id: id },
        };
        return await this.$store.dispatch('staffs/searchStaff', params);
      } else {
        let params = {
          profile: true,
        };
        try {
          return await this.$store.dispatch('staffs/searchStaff', params);
        } catch (error) {
          await this.$store.dispatch('logout');
        }
      }
    },

    /* Get department api from backend  */
    async getDepartmentData(companyid = 1) {
      if (!this.departData || !this.departData.length) {
        let params = {
          filter: {
            ...{ company_id: companyid },
          },
        };
        await this.$store.dispatch('departments/list', params);
      } else {
        return this.departData;
      }
    },

    /* Get department api from backend  */
    async getDepartmentKeyData(companyid = 1) {
      if (!this.depKeyData || !Object.values(this.depKeyData)?.length) {
        let params = {
          filter: {
            company_id: companyid,
          },
        };
        await this.$store.dispatch('departments/listKey', params);
      }
      return this.depKeyData;
    },

    /* Get department api from backend  */
    async getPositionKeyData(companyid = 1) {
      if (!this.posKeyData || !Object.values(this.posKeyData)?.length) {
        let params = {
          filter: {
            company_id: companyid,
          },
        };
        await this.$store.dispatch('positions/listKey', params);
      }
      return this.posKeyData;
    },

    /* Get working api from backend */
    async getWorkingData(companyid) {
      if (!this.workingData || !this.workingData.length) {
        let params = {
          filter: {
            ...{ status: 'active' },
            ...{ company_id: companyid },
          },
        };
        await this.$store.dispatch('workingtimes/list', params);
      }
    },

    /* Get province api from backend */
    async getProvinceData() {
      if (!this.provinceData || !this.provinceData.length) {
        await this.$store.dispatch('provinces/listProvince');
      }
    },

    /* Get district api from backend */
    async getDistrictData(provinceid) {
      if (!this.districtData || !this.districtData.length) {
        let params = {
          filter: {
            ...{ province_id: provinceid },
          },
        };
        await this.$store.dispatch('provinces/listDistrict');
      }
    },

    /* Get district api from backend */
    async getSubDistrictData(id) {
      if (!this.subdistData || !this.subdistData.length) {
        // let params = {
        //     filter: {
        //       ...{province_id: provinceid}
        //     },

        //  }
        await this.$store.dispatch('provinces/listSubDistrict');
      }
    },

    formatDateTo24(datetime) {
      var date = datetime.split(' ');
      var time = date[1].split(':');
      var hours = Number(time[0]);
      var minutes = Number(time[1]);
      var secords = Number(time[2]);
      if (date[2] == 'PM' && hours < 12) hours = hours + 12;
      if (date[2] == 'AM' && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      var sSecords = secords.toString();
      if (hours < 10) sHours = '0' + sHours;
      if (minutes < 10) sMinutes = '0' + sMinutes;
      if (secords < 10) sSecords = '0' + sSecords;
      return date[0] + ' ' + sHours + ':' + sMinutes + ':' + sSecords;
    },

    headdropdown() {
      var content = $('.contentleave', this.$el);
      content.slideToggle();
      content.css('display', 'grid');
    },
  },
};
