<template>
  <div class="content">
    <input type="hidden" v-model="emp_id" />

    <div v-for="(topic, index) in topics" :key="topic.id">
      <input
        type="hidden"
        v-model="topic.id"
        :name="'topic_id[' + emp_id + ']'"
      />

      <div
        v-if="topic.type == 1"
        class="row mt-5 mb-3 d-flex align-items-center"
      >
        <div class="offset-2"></div>
        <div class="col-sm-12 col-md-4 col-12">
          <label for="datepicker-valid">ตั้งแต่วันที่</label>
          <!-- v-model="topic.from_date" -->
          <b-form-datepicker
            v-model="from_date"
            :disabled="disabled_status"
          ></b-form-datepicker>
        </div>
        <div class="col-sm-12 col-md-4 col-12">
          <label for="datepicker-valid">ถึงวันที่</label>
          <b-form-datepicker
            v-model="to_date"
            :disabled="disabled_status"
          ></b-form-datepicker>
        </div>
        <div class="col-sm-12 col-md-1 col-12">
          <label for=""></label>
          <!-- <md-button
            class="md-primary"
            @click="cal_tardiness(topic)"
            :disabled="disabled_status"
          >
            คำนวณ
          </md-button> -->
        </div>
      </div>

      <div
        v-if="topic.type == 1 && tardiness?.realtime"
        class="row mt-5 mb-3 d-flex align-items-center"
      >
        <div class="offset-1 col-10">
          <div class="table-responsive">
            <table class="table custom-table">
              <thead>
                <tr>
                  <th class="text-center" width="20%">ประเภท</th>
                  <th class="text-center">มาทำงาน</th>
                  <th class="text-center">ขาด</th>
                  <th class="text-center">ลาไม่รับค่าจ้าง</th>
                  <th class="text-center">สาย(นาที)</th>
                  <th class="text-center">สาย(ครั้ง)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center" width="20%">ประมวลแล้ว</td>
                  <td class="text-center">
                    {{
                      tardiness.processed?.work ? tardiness.processed.work : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.processed?.notwork
                        ? tardiness.processed.notwork
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.processed.leave_count?.wop_day
                        ? tardiness.processed.leave_count.wop_day
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.processed?.late_off_mins
                        ? tardiness.processed.late_off_mins
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.processed?.count_late
                        ? tardiness.processed.count_late
                        : 0
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-center">รอประมวล</td>
                  <td class="text-center">
                    {{ tardiness.realtime?.work ? tardiness.realtime.work : 0 }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.realtime?.notwork
                        ? tardiness.realtime.notwork
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.realtime.leave_count?.wop_day
                        ? tardiness.realtime.leave_count.wop_day
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.realtime?.late_off_mins
                        ? tardiness.realtime.late_off_mins
                        : 0
                    }}
                  </td>
                  <td class="text-center">
                    {{
                      tardiness.realtime?.count_late
                        ? tardiness.realtime.count_late
                        : 0
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="offset-1 col-8">
          <p class="d-flex align-items-center">
            {{ index + 1 }}.&nbsp; <b>{{ topic.appraisal_topic_name }}</b
            >&ensp;
            <span v-if="topic.type != 3">
              <b-icon
                icon="exclamation-circle"
                title="เกณฑ์ในการให้คะแนน"
                v-b-toggle="`collapse-${topic.id}`"
                class="m-1 "
                @click="topic.visible = !topic.visible"
              ></b-icon>
            </span>
          </p>
          <!-- <p v-html="topic.description"></p> -->
        </div>
        <div v-if="topic.type != 3">
          <div class="col-3">
            <b-form-rating
              v-model="topic.scores"
              :variant="topic.type != 1 ? 'warning' : ''"
              no-border
              size="lg"
              id="rating-lg-no-border"
              :readonly="topic.type == 1 || disabled_status"
            ></b-form-rating>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-1 col-10">
          <div v-if="topic.type != 3">
            <b-collapse :id="`collapse-${topic.id}`">
              <div class="table-responsive">
                <table class="table custom-table">
                  <thead>
                    <tr>
                      <th class="text-center">เกณฑ์ในการให้คะแนน</th>
                      <th class="text-center" width="20%">คะแนน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="scoreDetail in topic.scoring"
                      :key="scoreDetail.value"
                    >
                      <td>{{ scoreDetail.score_text }}</td>
                      <td class="text-center">{{ scoreDetail.score_point }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="offset-1 col-10">
          <div v-if="topic.type == 3">
            <md-field class="mx-3">
              <!-- <label>{{ topic.appraisal_topic_name }}</label> -->
              <md-textarea
                v-model="topic.comment"
                :name="'comment_id[' + emp_id + ']'"
                :disabled="disabled_status"
              ></md-textarea>
            </md-field>
          </div>
        </div>
      </div>
      <hr />

      <!-- <pre> -->
      <!-- {{ topics }} -->
      <!-- </pre> -->
    </div>

    <div class="text-center mt-3">
      <!-- Corrected button formatting -->
      <!-- <button type="button" class="btn btn-success" @click="saveAppraisal">
        SAVE
      </button> -->
    </div>
  </div>
</template>

<script>
import { loading_start, loading_close } from '@/utils/loading.js';

export default {
  data() {
    return {
      topics: [],
      tardiness: [],
      from_date: null,
      to_date: null,
      tardiness_score: 0,
    };
  },

  props: {
    emp_id: { default: '' },
    score_id: { type: String, default: '' },
    disabled_status: { type: Boolean, default: false },
    gfrom_date: { type: String, default: null },
    gto_date: { type: String, default: null },
    level: { default: 0 },
  },

  created() {
    this.getTopic();
    if (this.gfrom_date) {
      this.from_date = this.gfrom_date;
    }
    if (this.gto_date) {
      this.to_date = this.gto_date;
    }
  },

  watch: {
    async from_date(val) {
      if (val && this.to_date && this.disabled_status == false) {
        if (this.disabled_status == false && this.topics) {
          let score = await this.get_time_process(this.from_date, this.to_date);
          this.cal_score_topic_type(1, Math.ceil(score), 'Y');
        }
      }
    },
    async to_date(val) {
      if (val && this.from_date && this.disabled_status == false) {
        if (this.disabled_status == false && this.topics) {
          let score = await this.get_time_process(this.from_date, this.to_date);
          this.cal_score_topic_type(1, Math.ceil(score), 'Y');
        }
      }
    },
  },

  methods: {
    async get_time_process(from_date, to_date) {
      var res = await this.$store.dispatch('appraisal/get_time_process', {
        emp_id: this.emp_id,
        from_date: from_date,
        to_date: to_date,
      });
      this.tardiness = res;

      var count = 0;
      var list = ['leave_count', 'notwork', 'count_late'];
      Object.entries(res).forEach(([key, value]) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (list.includes(subKey) && subKey != 'leave_count') {
              count += subValue;
            } else if (list.includes(subKey) && subKey == 'leave_count') {
              if (typeof subValue === 'object' && !Array.isArray(subValue)) {
                Object.entries(subValue).forEach(([sub2Key, sub2Value]) => {
                  if (sub2Key == 'wop_day') {
                    count += sub2Value;
                  }
                });
              }
            }
          });
        }
      });
      this.tardiness_score = count;
      return count;
    },

    async getTopic() {
      loading_start();
      if (this.disabled_status == false) {
        var res = await this.$store.dispatch('appraisal/get_topics');
      } else {
        var res = await this.$store.dispatch('appraisal/get_topics_score', {
          score_id: this.score_id,
        });
      }
      res.forEach((r) => {
        if (!r.options) {
          r.options = [];
        }
        if (r.scoring) {
          r.scoring.forEach((v) => {
            r.options.push({ text: v.score_point, value: v.score_point });
          });
          r.options.sort((a, b) => a.value - b.value);
        }
      });
      this.topics = res;
      loading_close();
    },

    async saveAppraisal() {
      var data_set = {};
      var data_score = {};
      let invalid = false;
      this.topics.forEach((to) => {
        if (!to.scores && to.type != 3) {
          invalid = true;
          return;
        }

        data_score[to.id] = {
          appraisal_topic_id: to.id,
          emp_id: this.emp_id,
          score: to.scores ?? 0,
          comment: to.comment ?? '',
        };
      });
      data_set = {
        from_date: this.from_date,
        to_date: this.to_date,
        level: this.level ?? 1,
        data_score,
      };

      if (invalid) {
        this.$store.dispatch('alerts/error', 'กรุณาให้คะแนนให้ครบถ้วน');
        return { status: false };
      }

      let res = await this.$store.dispatch(
        'appraisal/save_appraisal',
        data_set,
      );

      return res;
    },

    // async cal_tardiness(obj) {
    //   console.log(obj);
    //   if (obj.from_date && obj.to_date) {
    //     let from_date = this.date_to_time(obj.from_date);
    //     let to_date = this.date_to_time(obj.to_date);
    //     if (from_date >= to_date) {
    //       this.$store.dispatch(
    //         'alerts/error',
    //         'กรุณาเช็ควันที่ ' + obj.appraisal_topic_name,
    //       );
    //     } else {
    //       // let res = await this.$store.dispatch(
    //       //   'appraisal/save_appraisal',
    //       //   data_set,
    //       // );
    //       console.log(obj);

    //       // alert('คำนวณวันที่');
    //     }
    //   } else {
    //     this.$store.dispatch(
    //       'alerts/error',
    //       'กรุณาเช็ควันที่ ' + obj.appraisal_topic_name,
    //     );
    //   }
    // },

    date_to_time(date) {
      if (date_to_time) {
        var myDate = date.split('-');
        var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
        return newDate.getTime();
      }
    },

    cal_score_topic_type(type, score_chk, update = '') {
      var result = [];
      let topics = [...this.topics];
      topics.forEach((obj) => {
        if (obj.type == type) {
          result[obj.id] = this.calculate_rang(
            obj.scoring,
            parseFloat(score_chk),
          );
          if (update == 'Y') {
            obj.scores = result[obj.id];
          }
        }
      });
      this.topics = topics;
      return result;
    },

    calculate_rang(arr, score) {
      var max = 0;
      var min = 0;
      var score_result = 0;

      arr.forEach((obj) => {
        if (obj.score_range1 && obj.score_range2) {
          let tmpMax = Math.max(obj.score_range1, obj.score_range2);
          let tmpMin = Math.min(obj.score_range1, obj.score_range2);
          if (min > tmpMin) {
            min = tmpMin;
          }
          if (max < tmpMax) {
            max = tmpMax;
          }
          if (tmpMin <= parseFloat(score) && parseFloat(score) <= tmpMax) {
            score_result = parseFloat(obj.score_point);
          }
        } else {
          if (parseFloat(obj.score_range1) < min && parseFloat(score) < min) {
            score_result = parseFloat(obj.score_point);
          }
          if (max < parseFloat(obj.score_range1) && max < parseFloat(score)) {
            score_result = parseFloat(obj.score_point);
          }
        }
      });
      return score_result;
    },
  },
};
</script>

<style scoped>
/* Add any custom styling here */
</style>
