<template>
  <div class="createMemo">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green add-memo">
        <div class="card-icon">
          <md-icon>add_circle_outline</md-icon>
        </div>
        <h4 class="title"></h4>
        <div class="text-right">
          <md-button @click="goBack" class="md-primary md-dense">
            Back to List
          </md-button>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter md-alignment-center-right">
          <b-button variant="info" @click="previewPDF">Preview</b-button>
        </div>
        <div class="md-layout md-gutter md-alignment-center-center">
          <div class="md-layout-item md-size-30">
            <md-field>
              <label>สาขา</label>
              <md-select v-model="branch_id">
                <md-option
                  v-for="(item, index) in branchList"
                  :key="index + '-' + item.id"
                  :value="item.id"
                >
                  {{ item.br_nameth }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <img
                v-show="showLogo"
                :src="branchLogo[branch_id]"
                style="width:auto; height: 100px;"
              />
            </center>
          </div>
          <div class="md-layout-item md-size-100">
            <center><span class="headMemo">MEMORANDAM</span></center>
          </div>
          <div class="md-layout-item md-size-100">
            <center>
              <b-table-simple class="memo-table">
                <b-thead>
                  <b-tr>
                    <b-td>
                      <p>TO:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="toText"
                      ></b-input>
                    </b-td>
                    <b-td>
                      <p>DATE:</p>
                      <b-datepicker
                        class="b-datepicker-memo"
                        v-model="dateMemo"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        }"
                      ></b-datepicker>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>FROM:</p>
                      <b-input class="b-input-memo-table" v-model="fromText" />
                    </b-td>
                    <b-td>
                      <p>PAGE:</p>
                      <b-input class="b-input-memo-table" v-model="pageText" />
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      <p>SUBJECT:</p>
                      <b-input
                        class="b-input-memo-table"
                        v-model="subjectText"
                      />
                    </b-td>
                    <b-td>
                      <p>CC:</p>
                      <!-- <b-input class="b-input-memo-table" v-model="cc"></b-input> -->
                      <b-input class="b-input-memo-table" v-model="cc" />
                    </b-td>
                  </b-tr>
                </b-thead>
              </b-table-simple>
            </center>
          </div>
          <div
            class="md-layout-item md-size-100"
            v-if="check_showeditor && branchLogo[branch_id]"
          >
            <hr />
            <div class="d-flex justify-content-end my-2">
              <b-button variant="success" @click="addPDFFile"
                >Add PDF File
              </b-button>
            </div>
            <PdfmeEditor
              v-model="pdfme_template"
              ref="pdfmeEditor"
              :baseTemplate="baseTemplate"
              :contentTemplate="pdfme_template"
              @getPdfTemplate="getPdfTemplate($event)"
              @onMountedPdfme="onMountedPdfme"
            >
            </PdfmeEditor>

            <div class="">
              <hr class="my-4" />

              <h5 class="ml-4">
                ผู้ลงนาม
              </h5>
              <PrioritySettings
                ref="prioritySettingsSignatories"
                :title="'ผู้ลงนาม'"
                :priorityGroups="staffPriorityGroups.signatories"
                :isGroupEnabled="false"
                @staffs="getStaffs"
                @staffsDelete="deleteStaffs"
                @onMountedPrioritySettings="
                  onMountedPrioritySettings('signatories')
                "
              ></PrioritySettings>
            </div>

            <div>
              <hr class="my-4" />
              <PrioritySettings
                ref="prioritySettingsApprovers"
                :title="'อนุมัติ'"
                :priorityGroups="staffPriorityGroups.approvers"
                :radioOptions="[
                  { value: 'acknowledge', name: 'Acknowledge' },
                  { value: 'approver', name: 'Approver' },
                ]"
                @staffs="staffs.approvers = $event"
                @onMountedPrioritySettings="
                  onMountedPrioritySettings('approvers')
                "
              ></PrioritySettings>
            </div>

            <div class="d-flex justify-content-end">
              <b-button class="mr-1" variant="primary" @click="saveMemo(false)">
                Save as Draft
              </b-button>
              <b-button class="ml-1" variant="success" @click="saveMemo(true)">
                Send for Approval
              </b-button>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import PdfmeEditor from '@/components/Pdfme/RichEditor_Pdfme.vue';
import { loading_close, loading_start } from '@/utils/loading.js';
import Vuex from 'vuex';
import Swal from 'sweetalert2';
import PrioritySettings from '@/components/PrioritySettings.vue';
import employeeMixin from '@/mixins/employee-mixin';
import MemoMixin from '@/components/Pdfme/BaseTemplate/MemoMixin';

export default {
  mixins: [employeeMixin, MemoMixin],
  components: {
    PrioritySettings: PrioritySettings,
    PdfmeEditor: PdfmeEditor,
  },
  async beforeCreate() {
    const params = this.$route.params;

    if (params?.id) {
      const response = await this.$store.dispatch('memo/getMemoDetail', params);
      if (response?.data) {
        const memoDetail = response.data.memoDetail;
        this.memo.id = memoDetail.id;
        this.memo.previous_imagesids = response.data.imageIds;
        this.memo.branch_id = memoDetail.bch_id;
        this.branch_id = memoDetail.bch_id;
        this.dateMemo = memoDetail.date;
        this.toText = memoDetail.to_text;
        this.fromText = memoDetail.from_text;
        this.pageText = memoDetail.page;
        this.subjectText = memoDetail.subject;
        this.cc = memoDetail.cc;
        this.pdfme_template = memoDetail.memo_data;
        this.showeditor = this.check_showeditor;
        if (response.data.approvers.length > 0) {
          this.approvers = response.data.approvers;
        }
      }
    }
  },
  async created() {
    this.branchList = await this.getBranchData();
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
      branchData: (state) => state.branches.list,
    }),
    check_showeditor() {
      return this.set_showeditor();
    },
    memorandam() {
      const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      };

      const date = new Intl.DateTimeFormat('en-US', options).format(
        this.dateMemo ? new Date(this.dateMemo) : new Date(),
      );

      return {
        toText: this.toText,
        fromText: this.fromText,
        dateMemo: date,
        pageText: this.pageText,
        subjectText: this.subjectText,
        cc: this.cc,
      };
    },
  },
  data() {
    return {
      dateMemo: '',
      toText: '',
      fromText: '',
      pageText: 1,
      subjectText: '',
      cc: '',
      pdfme_template: undefined,
      baseTemplate: {
        schemas: [[]],
        basePdf: {},
        pdfmeVersion: '',
      },
      showLogo: false,
      branchLogo: {},
      branch_id: undefined,
      brname_en: undefined,
      branchList: undefined,
      showeditor: false,
      memo: {},
      approvers: {},
      staffPriorityGroups: { approvers: undefined, signatories: undefined },
      staffs: { approvers: undefined, signatories: undefined },
    };
  },
  watch: {
    async branch_id() {
      this.showLogo = false;
      await this.getBranchLogo();
      this.showLogo = true;
    },
    memorandam(value) {
      const memorandam = value;
      this.debounceUpdate(() => {
        if (this.getMemorandumTableTemplate({ memorandam })?.head) {
          this.$refs.pdfmeEditor?.updateContent(
            'memorandam_table',
            this.getMemorandumTableTemplate({ memorandam })?.head,
            'head',
          );
          this.$refs.pdfmeEditor?.updateContent(
            'memorandam_table',
            this.getMemorandumTableTemplate({ memorandam })?.content,
            'content',
          );
        }
      });
    },
  },
  methods: {
    debounceUpdate(callback, delay = 800) {
      clearTimeout(this._timeout);
      this._timeout = setTimeout(callback, delay);
    },
    goBack() {
      let keySearchMemo = this.$route.params.keySearch;
      let currentPageMemo = this.$route.params.currentPage;

      let params = {
        keySearchMemo,
        currentPageMemo,
      };

      this.$router.push({
        path: 'memoList',
        name: 'จัดการ Memo',
        params: params,
      });
    },
    set_showeditor() {
      if (this.showeditor == false) {
        this.showeditor =
          this.branch_id != null &&
          this.toText?.length > 0 &&
          this.dateMemo?.length > 0 &&
          this.pageText > 0 &&
          this.subjectText?.length > 0 &&
          this.cc?.length > 0;
      }
      return this.showeditor;
    },
    async getBranchLogo() {
      if (this.branch_id == null) {
        await this.$store.dispatch('alerts/error', 'กรุณาเลือกสาขา');
        return;
      }

      if (
        this.branchLogo &&
        typeof this.branchLogo[this.branch_id] != 'undefined'
      ) {
        return this.branchLogo[this.branch_id];
      }

      let params = {
        bch_id: this.branch_id,
      };

      loading_start();
      let branchLogo = await this.$store.dispatch('pdfs/getBranchLogo', params);
      loading_close();

      if (branchLogo.length > 0) {
        this.branchLogo[this.branch_id] = branchLogo[0].image;
      } else {
        await this.$store.dispatch('alerts/error', 'ไม่มีโลโก้ของสาขานี้');
      }

      return branchLogo;
    },
    // PDFME
    async onMountedPdfme() {
      const branchLogo = this.branchLogo[this.branch_id];

      let baseTemplate = this.getBaseTemplate({
        branchLogo: branchLogo,
        memorandam: this.memorandam,
      });
      await this.$refs.pdfmeEditor.updateBaseTemplate(baseTemplate);
    },
    async previewPDF() {
      try {
        if (this.$refs.pdfmeEditor) {
          await this.$refs.pdfmeEditor.generatePDF();
        }
      } catch (error) {
        throw 'Error generating PDF: ' + error;
      }
    },
    async saveMemo(sendForApproval) {
      try {
        this.pdfme_template = await this.$refs.pdfmeEditor.designer.getTemplate();
        this.pageText = this.pdfme_template.schemas.length;

        const memo_data = JSON.stringify(this.pdfme_template);
        this.memo = {
          id: this.memo?.id,
          bch_id: this.branch_id,
          memo_name: this.subjectText,
          to_text: this.toText,
          date: this.dateMemo,
          from_text: this.fromText,
          page: this.pdfme_template.schemas.length,
          subject: this.subjectText,
          cc: this.cc,
          memo_data: memo_data,
          previous_imagesids: JSON.stringify(this.memo?.imageIds),
          approver: JSON.stringify([
            ...Object.values(this.$refs.prioritySettingsApprovers.getAll()),
            ...Object.values(this.$refs.prioritySettingsSignatories.getAll()),
          ]),
          status: sendForApproval ? 'approve' : 'draft',
        };

        loading_start();
        const response = await this.$store.dispatch('memo/saveMemo', this.memo);
        if (response) {
          this.memo = response.data;
          if (response) {
            this.memo = response.data;
            loading_close();
            this.goBack();
            await Swal.fire('บันทึกสำเร็จ!', '', 'success');
          }
        }
      } catch (error) {
        loading_close();
        await Swal.fire('มีบางอย่างผิดพลาด!', '', 'error');
      } finally {
        loading_close();
      }
    },
    async updateSignatures(employees, type = 'add') {
      if (
        !employees ||
        (Object.values(employees).length <= 1 && !this.memo.id && type == 'add')
      ) {
        return false;
      }
      const contentTemplate = this.$refs.pdfmeEditor.designer.getTemplate();
      const validKeys = new Set();

      const allExistingNames = new Set();

      for (
        let pageIndex = 0;
        pageIndex < contentTemplate.schemas.length;
        pageIndex++
      ) {
        contentTemplate.schemas[pageIndex].forEach((schema) => {
          if (schema.name?.startsWith('signature-')) {
            allExistingNames.add(schema.name);
          }
        });
      }

      const lastPageIndex = contentTemplate.schemas.length - 1;

      for (const employeeKey in employees) {
        const employee = employees[employeeKey];
        const nameKey = `${employee.priority}${employee.order_person}`;
        const signatureName = `signature-${nameKey}`;
        validKeys.add(signatureName);

        if (!employee?.emp_id) continue;

        if (allExistingNames.has(signatureName)) {
          continue;
        }

        const data = this.setDataEmployee(employee);
        const signatureTemplate = this.getTemplateSignature();

        const inputsTemplate = this.getInputsSignature({
          type: data.type,
          name: `คุณ${employee.emp_first_name} ${employee.emp_last_name}`,
          position: employee.position,
        });

        const newTemplateData = {
          template: {
            schemas: [[...signatureTemplate]],
            basePdf: { width: 55, height: 28, padding: [0, 0, 0, 0] },
            pdfmeVersion: '5.0.0',
          },
          inputs: [inputsTemplate],
          name: signatureName,
        };

        const positions = await this.$refs.pdfmeEditor.getTemplatePosition(
          contentTemplate,
          false,
          lastPageIndex,
        );

        const newTemplate = await this.$refs.pdfmeEditor.convertPdfToBase64(
          newTemplateData,
          {
            x: positions.x + 5,
            y: positions.y + 15,
          },
        );

        contentTemplate.schemas[lastPageIndex].push({
          ...newTemplate,
        });

        allExistingNames.add(signatureName);
      }

      for (
        let pageIndex = 0;
        pageIndex < contentTemplate.schemas.length;
        pageIndex++
      ) {
        contentTemplate.schemas[pageIndex] = contentTemplate.schemas[
          pageIndex
        ].filter(
          (schema) =>
            !schema.name?.startsWith('signature-') ||
            validKeys.has(schema.name),
        );
      }

      await this.$refs.pdfmeEditor.designer.updateTemplate(contentTemplate);
    },
    getPdfTemplate(template) {
      this.pdfme_template = template;
    },
    setDataEmployee(employee) {
      const typeList = {
        createBy: 'จัดทำโดย',
        approver: 'อนุมัติโดย',
        acknowledge: 'รับทราบโดย',
      };
      const firstName =
        employee?.emp_first_name ||
        employee?.firstname_th ||
        employee?.first_nameth;
      const lastName =
        employee?.emp_last_name ||
        employee?.last_nameth ||
        employee?.last_nameth;
      return {
        emp_id: employee.emp_id,
        name: `คุณ${firstName} ${lastName}`,
        position:
          employee?.position || employee?.salarys?.[0]?.positions?.posname_en,
        type: typeList[employee.type],
      };
    },
    // Priority Settings
    async onMountedPrioritySettings(type) {
      if (type == 'signatories') {
        const psSignatories = this.$refs.prioritySettingsSignatories;
        psSignatories.priorityGroups[0].priorityType = 'signer';

        let staff = [];
        if (!this.memo?.id) staff.push(this.setCreator());

        if (this.approvers.length > 0) {
          staff.push(...Object.values(this.approvers));
        }

        if (!staff.length) return false;

        const signatories = staff.filter((item) => item.type === 'signer');
        this.staffPriorityGroups.signatories = psSignatories.formatPriorityGroups(
          signatories,
        );

        this.staffs.signatories = psSignatories.getAll(
          this.staffPriorityGroups.signatories,
        );
      }
      if (type == 'approvers') {
        const psApprovers = this.$refs.prioritySettingsApprovers;
        // add disabledCondition to prioritySettingsApprovers
        const branchField = psApprovers.inputSchemas.find(
          (field) => field.key === 'branch',
        );
        branchField.disabledCondition = 'priorityType';
        let approvers = this.approvers ?? [];
        if (!approvers.length) return false;

        approvers = approvers.filter((item) => item.type !== 'signer');
        this.staffPriorityGroups.approvers = psApprovers.formatPriorityGroups(
          approvers,
        );

        this.staffs.approvers = psApprovers.getAll(
          this.staffPriorityGroups.approvers,
        );
      }
    },
    setCreator() {
      const { branch_id, employee_id, employees } = this.me;
      const { salarys } = employees;
      const { dep_id, pos_nameen } = salarys[0].positions;
      return {
        bch_id: branch_id,
        dep_id,
        depname_en: salarys[0].positions.departments.dep_nameen,
        emp_id: employee_id,
        firstname_en: employees.first_nameen,
        lastname_en: employees.last_nameen,
        firstname_th: employees.first_nameth,
        lastname_th: employees.last_nameth,
        pos_id: salarys[0].position_id,
        posname_en: pos_nameen,
        position: pos_nameen,
        type: 'signer',
        order_person: 0,
        priority: 0,
      };
    },
    getStaffs(staffs) {
      this.staffs.signatories = staffs;
      this.updateSignatures(staffs);
    },
    deleteStaffs() {
      this.updateSignatures([], 'delete');
    },
    async addPDFFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf';
      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const dataURL = await this.convertFileToDataURL(file);
          this.handlePDFDataURL(dataURL);
        }
      };
      input.click();
    },
    convertFileToDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(file);
      });
    },
    handlePDFDataURL(dataURL) {
      this.$refs.pdfmeEditor.clearTemplate();
      this.$refs.pdfmeEditor.onChangeBasePDF(dataURL);
    },
  },
};
</script>
<style lang="scss">
.createMemo {
  .table.memo-table {
    width: 700px;
    margin-bottom: 1rem;
    font-weight: bold;

    td {
      width: 50%;
    }
  }

  .memo-table {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
  }

  .headMemo {
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 26px;
  }

  .b-input-memo-table {
    display: inline-block;
    width: auto;
    font-family: 'Angsana New';
    font-weight: bold;
    font-size: 25px;
    color: black;
    background-image: none;

    &:focus {
      color: black;
    }
  }

  .b-datepicker-memo.b-form-btn-label-control
    .dropdown
    .b-form-datepicker
    .b-datepicker-memo
    .form-control {
    label {
      display: inline-block;
      font-family: 'Angsana New';
      font-weight: bold;
      font-size: 18px;
      color: black;
    }
  }

  .dropdown-menu .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    font-size: 23px;
    line-height: 0.7;
  }

  .b-calendar-grid small {
    font-size: 19px;
  }

  .b-calendar-grid-caption {
    font-size: 24px;
  }

  .b-calendar-inner bdi {
    font-size: 18px;
  }

  table {
    td {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      height: 60px;
    }

    p {
      width: 130px;
      margin: 0;
    }

    input .b-input-memo-table {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 200;
    }

    .form-control,
    .cc {
      height: 35px;
      width: 100%;
    }
  }

  .form-control {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
</style>
